/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState, useContext, useCallback } from 'react'
// import axios from "axios";

// react-router-dom components
import { Link } from 'react-router-dom'

// @mui material components
import Card from '@mui/material/Card'
import Switch from '@mui/material/Switch'
import Grid from '@mui/material/Grid'
import MuiLink from '@mui/material/Link'
import Button from '@mui/material/Button'

// @mui icons
import FacebookIcon from '@mui/icons-material/Facebook'
import GitHubIcon from '@mui/icons-material/GitHub'
import GoogleIcon from '@mui/icons-material/Google'

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDInput from 'components/MDInput'

// Authentication layout components
import BasicLayout from 'layouts/authentication/components/BasicLayout'

// Images
import bgImage from 'assets/images/bg-sign-in-basic.jpeg'
import AuthContext from 'pagesmu/store/auth-context'

// import ComponentsContext from 'pagesmu/store/components-context'

// Hooks
import useHttp from '../../../hooks/use-http'

function GestLogin() {
  const [rememberMe, setRememberMe] = useState(false)
  const [loginemail, setLoginEmail] = useState('freelance6.cognome6@gmail.com')
  const [loginpassword, setLoginPassword] = useState(false)
  const { login } = useContext(AuthContext)
  // const compCtx = useContext(ComponentsContext)
  // const origin = 'https://localhost.nsp-staging.click'

  const {
    sendRequest: sendLoginRequest
    // isLoading: isLoginLoading,
    // error: loginError
  } = useHttp()

  const handleSetRememberMe = () => setRememberMe(old => !old)

  const loginNameHandler = event => {
    setLoginEmail(event.target.value)
  }
  const loginPasswordHandler = event => {
    setLoginPassword(event.target.value)
  }

  const loginManage = useCallback(res => {
    // console.log('LA RES MIRABOLANTE', res)
    login(res.data.token, '')
  }, [])

  const submitHandler = () => {
    const body = {
      email: loginemail,
      password: loginpassword
    }

    sendLoginRequest({
      url: '/api/login',
      methodH: 'POST',
      bodyH: body,
      manageData: loginManage
    })
  }
  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
          <Grid
            container
            spacing={3}
            justifyContent="center"
            sx={{ mt: 1, mb: 2 }}
          >
            <Grid item xs={2}>
              <MDTypography
                component={MuiLink}
                href="#"
                variant="body1"
                color="white"
              >
                <FacebookIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography
                component={MuiLink}
                href="#"
                variant="body1"
                color="white"
              >
                <GitHubIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography
                component={MuiLink}
                href="#"
                variant="body1"
                color="white"
              >
                <GoogleIcon color="inherit" />
              </MDTypography>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                value={loginemail}
                onChange={loginNameHandler}
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                onChange={loginPasswordHandler}
                fullWidth
              />
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: 'pointer', userSelect: 'none', ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <Button
                variant="gradient"
                color="primary"
                onClick={submitHandler}
                style={{
                  color: '#FFFFFF',
                  backgroundColor: '#1A73E8'
                }}
                fullWidth
              >
                sign in
              </Button>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Don&apos;t have an account?{' '}
                <MDTypography
                  component={Link}
                  to="/authentication/sign-up/cover"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign up
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  )
}

export default GestLogin
