// @mui material components
import Grid from '@mui/material/Grid'
// import { Dropzone, FileItem } from '@dropzone-ui/react'
// import Icon from '@mui/material/Icon'

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'
import MDDropzone from 'components/MDDropzone'
import GestUploadImg from 'pagesmu/CustomComponents/GestUploadImg/GestUploadImg'

function ImageGallery({ images, imageHandler, galleryRemoveHandler, pageId }) {
  return (
    <MDBox mt={1}>
      <Grid container spacing={3}>
        {images &&
          images
            .filter(data => data.main !== true)
            .map(image => (
              <Grid
                key={image.id}
                item
                xs={12}
                sm={pageId ? 12 : 6}
                lg={pageId ? 12 : 4}
              >
                <GestUploadImg
                  image={image.imagepath || image.image}
                  imageRemoveHandler={() => {
                    galleryRemoveHandler(image.id)
                  }}
                  isPage={!!pageId}
                />
              </Grid>
            ))}
        <Grid item xs={12} sm={pageId ? 12 : 6} lg={pageId ? 12 : 4}>
          <MDDropzone
            options={{
              maxFiles: 1,
              maxFilesize: 2,
              acceptedFIles: 'image/jpg, image/jpeg, image/png',
              disablePreviews: true,
              accept: file => {
                imageHandler(file, pageId)
              }
            }}
          />
          {/* <Dropzone
                onChange={updateGallery}
                value={files}
                maxFiles={1}
                maxFileSize={2998000}
                accept=".jpg,.jpeg,.png,image/*"
              >
                {files.map(file => 
                  <FileItem {...file} preview />
                ))}
              </Dropzone> */}
        </Grid>
      </Grid>
    </MDBox>
  )
}

export default ImageGallery
