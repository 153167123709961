/* eslint-disable no-alert */
import { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

// @mui material components
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'

// Material Dashboard 2 PRO React components
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'
import MDBox from 'components/MDBox'
import LoadingWrapper from 'pagesmu/CustomComponents/LoadingWrapper/LoadingWrapper'

// Material Dashboard 2 PRO React example components
import DashboardLayout from 'commoncomponent/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'commoncomponent/Navbars/DashboardNavbar'
import Footer from 'commoncomponent/Footer'

// Custom Hooks
import useHttp from 'hooks/use-http'

// EditSkill page components
import Info from 'pagesmu/CustomComponents/Info'
import RightSidebar from 'pagesmu/CustomComponents/RightSidebar'
import ImageGallery from 'pagesmu/CustomComponents/ImageGallery'
import RepeaterPages from 'pagesmu/CustomComponents/Repeaters/RepeaterPages'

import {
  removeNonIntIds,
  prepImage,
  remSpaces,
  feedback
} from '../../utils/utils'

function EditTemplateSmu() {
  const { id: pId } = useParams()

  const [nameTemplate, setNameTemplate] = useState('')
  const [descriptionTemplate, setDescriptionTemplate] = useState('')
  const [techDescriptionTemplate, setTechDescriptionTemplate] = useState('')
  const [compatibilityTemplate, setCompatibilityTemplate] = useState('')
  const [linkTemplate, setLinkTemplate] = useState('')
  const [imageTemplate, setImageTemplate] = useState('')
  const [imageExtensionTemplate, setImageExtensionTemplate] = useState('')
  const [priceTemplate, setPriceTemplate] = useState(0)
  const [packageTemplate, setPackageTemplate] = useState('')
  const [newPackageTemplate, setNewPackageTemplate] = useState('')
  const [demoLinkTemplate, setDemoLinkTemplate] = useState('')
  const [envatoIdTemplate, setEnvatoIdTemplate] = useState('')
  const [galleryTemplate, setGalleryTemplate] = useState([])
  const [pagesTemplate, setPagesTemplate] = useState([])
  const [pagesOrderDuplicatesTemplate, setPagesOrderDuplicatesTemplate] =
    useState([])
  const [tagsTemplate, setTagsTemplate] = useState([])
  const [allTags, setAllTags] = useState([])
  const [allProjects, setAllProjects] = useState([])

  const {
    sendRequest: sendTemplateRequest,
    isLoading: isTemplateLoading
    // error: templateError
  } = useHttp()

  const manageTemplateGet = ({
    nome: name,
    descrizione: description,
    immaginepath: imagepath,
    estensioneimmagine: imageExtension,
    costo: price,
    link: linkG,
    compatibilita: compatibility,
    descrizionetecnica: techDescription,
    pcchettopath: packageG,
    linkdemo: demolink,
    idenvato: envatoId,
    images: imagesG,
    tags: tagsG,
    pages: pagesG
  }) => {
    setNameTemplate(name)
    setDescriptionTemplate(description)
    setImageTemplate(imagepath)
    setImageExtensionTemplate(imageExtension)
    setPriceTemplate(price)
    setLinkTemplate(linkG || '')
    setCompatibilityTemplate(compatibility || '')
    setTechDescriptionTemplate(techDescription || '')
    setPackageTemplate(packageG || '')
    setDemoLinkTemplate(demolink || '')
    setEnvatoIdTemplate(envatoId || '')
    setGalleryTemplate(imagesG)
    setTagsTemplate(tagsG)
    setPagesTemplate(pagesG)
  }

  useEffect(() => {
    if (pId) {
      sendTemplateRequest({
        url: `/sitemeup/template?id=${pId}`,
        manageData: ({ data }) => {
          manageTemplateGet(data)
        }
      })
    }
  }, [pId])

  const nameHandler = e => {
    setNameTemplate(e.target.value)
  }

  const descriptionHandler = e => {
    setDescriptionTemplate(e.target?.value)
  }

  const techDescriptionHandler = e => {
    setTechDescriptionTemplate(e.target.value)
  }

  const compatibilityHandler = e => {
    setCompatibilityTemplate(e)
  }

  const linkHandler = e => {
    setLinkTemplate(e.target.value)
  }

  const demoLinkHandler = e => {
    setDemoLinkTemplate(e.target.value)
  }

  const envatoIdHandler = e => {
    setEnvatoIdTemplate(e.target.value)
  }

  const priceHandler = e => {
    setPriceTemplate(e.target.value)
  }

  const pagesHandler = param => (value, id) => {
    setPagesTemplate(old =>
      old.map(page => (page.id === id ? { ...page, [param]: value } : page))
    )
  }

  useEffect(() => {
    const ordering = pagesTemplate.map(page => parseInt(page.ordinamento, 10))
    setPagesOrderDuplicatesTemplate(
      ordering.filter((el, i) => ordering.indexOf(el) !== i)
    )
  }, [pagesTemplate])

  const addPageHandler = () => {
    setPagesTemplate(old => [
      ...old,
      {
        id: `${old?.length + 1}prov`,
        title: '',
        description: '',
        images: [],
        id_tipoprogetto: null,
        ordinamento: old?.length + 1
      }
    ])
  }

  const imageHandler = (image, pageId) => {
    // const mime = image.type
    const reader = new FileReader()
    reader.readAsDataURL(image)
    reader.onload = e => {
      // const mime = e.target.result.match(/^data:(.*);/)[1]
      // const base64 = e.target.result.replace(`data:${mime};base64,`, '')
      const base64 = e.target.result
      // console.log('MAINIMAGE', base64)
      if (pageId) {
        // console.log('IMG HANDLER PAGE', e.target.result, pageId)
        setPagesTemplate(old =>
          old.map(o =>
            o.id === pageId
              ? {
                  ...o,
                  images: [
                    ...o.images,
                    {
                      id: `${o.images.length}prov`,
                      id_page: pageId,
                      image: e.target.result,
                      estensione: image.type.split('/')[1],
                      title: `${o.title}-${o.images.length + 1}`,
                      description: `${o.description}-${o.images.length + 1}`,
                      name: `${remSpaces(o.title)}-${o.images.length + 1}`
                    }
                  ]
                }
              : o
          )
        )
      } else {
        setImageTemplate(base64)
        setImageExtensionTemplate(image.type.split('/')[1])
      }
    }
  }

  const imageRemoveHandler = () => {
    setImageTemplate('')
    setImageExtensionTemplate('')
  }

  const galleryUpdateHandler = image => {
    const reader = new FileReader()
    reader.readAsDataURL(image)
    reader.onload = e => {
      const base64 = e.target.result
      setGalleryTemplate(old => [
        ...old,
        {
          id: `${galleryTemplate.length + 1}prov`,
          id_template: pId,
          image: base64,
          estensione: image.type.split('/')[1],
          name: `${remSpaces(nameTemplate)}-${galleryTemplate.length + 1}`
        }
      ])
    }
  }

  const galleryRemoveHandler = id => {
    // if (id && pageId) {
    //   setPagesTemplate(old =>
    //     old.map(o =>
    //       o.id === pageId
    //         ? { ...o, images: o.images.filter(img => img.id !== id) }
    //         : o
    //     )
    //   )
    // } else
    setGalleryTemplate(old => old.filter(img => img.id !== id))
  }

  const {
    sendRequest: sendDeleteTemplatePageRequest,
    isLoading: isDelTemplatePageLoading
    // error: tagsError
  } = useHttp()

  const pageRemoveHandler = pageId => {
    if (window.confirm('Sei sicuro di voler eliminare la pagina?')) {
      sendDeleteTemplatePageRequest({
        url: '/sitemeup/templatepagedelete',
        methodH: 'DELETE',
        bodyH: { id: pageId },
        manageData: res => {
          if (res.status === 'OK') {
            setPagesTemplate(old => old.filter(page => page.id !== pageId))
          }
          feedback(res)
        }
      })
    }
  }

  const {
    sendRequest: sendTagsRequest,
    isLoading: isTagsLoading
    // error: tagsError
  } = useHttp()

  const manageTagsGet = data => {
    setAllTags(data.map(t => ({ ...t, descrizione: t.description })))
    // setAllTags(data.map(e => ({ value: e.id, label: e.description })))
  }

  useEffect(() => {
    sendTagsRequest({
      url: '/sitemeup/tags',
      manageData: ({ data }) => {
        manageTagsGet(data)
      }
    })
  }, [pId])

  const tagsHandler = tags => {
    setTagsTemplate(tags)
  }

  const {
    sendRequest: sendProjectRequest,
    isLoading: isProjectLoading
    // error: tagsError
  } = useHttp()

  const manageProjectsTypeGet = data => {
    setAllProjects(data.map(proj => ({ id: proj.id, nome: proj.nome })))
    // setAllTags(data.map(e => ({ value: e.id, label: e.description })))
  }

  useEffect(() => {
    sendProjectRequest({
      url: '/sitemeup/tipologieprogetto',
      manageData: ({ data }) => {
        manageProjectsTypeGet(data)
      }
    })
  }, [pId])

  const packageHandler = files => {
    const reader = new FileReader()
    reader.readAsDataURL(files[0])
    reader.onload = e => {
      const base64 = e.target.result
      setNewPackageTemplate(base64)
    }
  }

  const {
    sendRequest: sendDelTemplateRequest,
    isLoading: isDelTemplateLoading
    // error: delSkillError
  } = useHttp()

  const navigate = useNavigate()

  const deleteTemplate = () => {
    if (window.confirm('Sei sicuro di voler eliminare questo Template?')) {
      sendDelTemplateRequest({
        methodH: 'DELETE',
        url: '/sitemeup/templatedelete',
        bodyH: { id: pId },
        manageData: () => navigate('/gestione/templates')
      })
    }
  }

  const {
    sendRequest: sendSaveRequest,
    isLoading: isSaveLoading
    // error: saveError
  } = useHttp()

  const saveHandler = () => {
    // console.log('Saving', tagsTemplate)
    const data = {
      id: pId,
      nome: nameTemplate,
      name: nameTemplate,
      descrizione: descriptionTemplate,
      link: linkTemplate,
      compatibilita: compatibilityTemplate,
      descrizionetecnica: techDescriptionTemplate,
      estensioneimmagine: imageExtensionTemplate,
      costo: priceTemplate,
      immagine: prepImage(imageTemplate),
      images: galleryTemplate.map(removeNonIntIds),
      pages: pagesTemplate
        .map(removeNonIntIds)
        .map(page => ({ ...page, images: page.images.map(removeNonIntIds) })),
      tags: tagsTemplate,
      pacchetto: newPackageTemplate,
      linkdemo: demoLinkTemplate,
      idenvato: envatoIdTemplate
    }
    if (pagesOrderDuplicatesTemplate.length) {
      window.alert(
        `L'ordinamento delle pagine contiene degli errori:
        Si prega di correggere e riprovare.`
      )
    } else {
      sendSaveRequest({
        methodH: pId ? 'POST' : 'PUT',
        url: pId ? '/sitemeup/templateupdate' : '/sitemeup/templatesave',
        bodyH: data,
        // eslint-disable-next-line no-alert
        manageData: () => window.alert('Template salvato')
      })
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={9}>
            <MDBox mb={3}>
              <Grid container spacing={3}>
                <LoadingWrapper
                  loading={
                    isTemplateLoading ||
                    isProjectLoading ||
                    isTagsLoading ||
                    isDelTemplateLoading ||
                    isDelTemplatePageLoading ||
                    isSaveLoading
                  }
                >
                  <Grid item xs={12}>
                    <Info
                      type="template"
                      name={nameTemplate}
                      nameHandler={nameHandler}
                      description={descriptionTemplate}
                      descriptionHandler={descriptionHandler}
                      compatibility={compatibilityTemplate}
                      compatibilityHandler={compatibilityHandler}
                      techDescription={techDescriptionTemplate}
                      techDescriptionHandler={techDescriptionHandler}
                      link={linkTemplate}
                      linkHandler={linkHandler}
                      demoLink={demoLinkTemplate}
                      demoLinkHandler={demoLinkHandler}
                      envatoId={envatoIdTemplate}
                      envatoIdHandler={envatoIdHandler}
                      tags={tagsTemplate}
                      allTags={allTags}
                      tagsHandler={tagsHandler}
                      packageTemplate={packageTemplate}
                      newPackageTemplate={newPackageTemplate}
                      packageHandler={packageHandler}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Card>
                      <MDBox p={3}>
                        <MDTypography variant="h5">Pagine</MDTypography>
                        <MDButton
                          onClick={() => addPageHandler()}
                          variant="gradient"
                          color="info"
                        >
                          + Pagina
                        </MDButton>
                        <MDBox mt={1}>
                          <Grid container spacing={3}>
                            <RepeaterPages
                              pages={pagesTemplate.map(page => ({
                                ...page,
                                pageTitleHandler: pagesHandler('title'),
                                pageDescriptionHandler:
                                  pagesHandler('description'),
                                galleryRemoveHandler,
                                imageHandler,
                                pageRemoveHandler,
                                allProjects,
                                projectTypeHandler:
                                  pagesHandler('id_tipoprogetto'),
                                pageOrderHandler: pagesHandler('ordinamento')
                              }))}
                              orderDuplicates={pagesOrderDuplicatesTemplate}
                            />
                          </Grid>
                        </MDBox>
                      </MDBox>
                    </Card>
                  </Grid>
                  <Grid item xs={12}>
                    <Card>
                      <MDBox p={3}>
                        <MDTypography variant="h5">Immagini</MDTypography>
                        <ImageGallery
                          images={galleryTemplate}
                          imageHandler={galleryUpdateHandler}
                          galleryRemoveHandler={galleryRemoveHandler}
                        />
                      </MDBox>
                    </Card>
                  </Grid>
                </LoadingWrapper>
              </Grid>
            </MDBox>
          </Grid>
          <Grid item xs={12} lg={3}>
            <RightSidebar
              section="template"
              price={priceTemplate}
              priceHandler={priceHandler}
              saveHandler={saveHandler}
              image={imageTemplate}
              imageHandler={imageHandler}
              imageRemoveHandler={imageRemoveHandler}
              deleteItem={deleteTemplate}
            />
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  )
}

export default EditTemplateSmu
