import React, { useContext } from 'react'

import ErrContext from 'pagesmu/store/error-context'

import classes from './ErrorWrapper.module.css'

const ErrorWrapper = ({ children }) => {
  const { errorText } = useContext(ErrContext)
  return (
    <div>
      {errorText !== '' && (
        <div className={classes.errorBanner}>
          {errorText} <div className={classes.errorButton}>X</div>
        </div>
      )}
      {children}
    </div>
  )
}

export default ErrorWrapper
