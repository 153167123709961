import { useState, useEffect } from 'react'

// @mui material components
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import Icon from '@mui/material/Icon'
import Divider from '@mui/material/Divider'

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'

// Material Dashboard 2 PRO React example components
import DashboardLayout from 'commoncomponent/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'commoncomponent/Navbars/DashboardNavbar'
import Footer from 'commoncomponent/Footer'
import DataTable from 'commoncomponent/Tables/DataTable'

// ProductsList page components
import { Link, useNavigate } from 'react-router-dom'
// useLocation can be used
// import Sidenav from 'pagesmu/gestskill/skillarchive/components/Sidenav/SidenavSkill'

import useHttp from 'hooks/use-http'
import tableData from 'pagesmu/CustomComponents/Archive/data/tableData'
import LoadingWrapper from '../LoadingWrapper/LoadingWrapper'

function capitalise(str) {
  return str.replace(/^./, char => char.toUpperCase())
}

function ArchiveSmu({ type }) {
  const [url, setUrl] = useState()
  const [section, setSection] = useState(type)
  // const [sections, setSections] = useState(null)
  const [createLink, setCreateLink] = useState(null)
  const [title, setTitle] = useState(`Elenco ${capitalise(type)}`)
  const [btnText, setBtnText] = useState(`Nuova ${capitalise(type)}`)
  const [dataTable, setDataTable] = useState({
    ...tableData(type),
    rows: []
  })

  useEffect(() => {
    switch (type) {
      case 'skill':
        setUrl('/sitemeup/freelanceskills')
        setSection(`${type}`)
        setTitle(`Elenco ${capitalise(type)}`)
        setBtnText(`Nuova ${type}`)
        setCreateLink('nuovaskill')
        break
      case 'freelance':
        setUrl('/sitemeup/tipologiefreelance')
        setSection('tipologiafreelance')
        setTitle(`Elenco Tipologie ${capitalise(type)}`)
        setBtnText('Nuova Tipologia')
        setCreateLink('nuovatipologia')
        break
      case 'progetto':
        setUrl('/sitemeup/tipologieprogetto')
        setSection('tipologiaprogetto')
        setTitle(`Elenco Tipologie ${capitalise(type)}`)
        setBtnText(`Nuovo ${type}`)
        setCreateLink('nuovoprogetto')
        break
      case 'template':
        setUrl('/sitemeup/templates')
        setSection(`${type}`)
        setTitle(`Elenco ${capitalise(type)}`)
        setBtnText(`Nuovo ${type}`)
        setCreateLink('nuovotemplate')
        break
      default:
        break
    }
  }, [type])

  const navigate = useNavigate()
  const {
    sendRequest,
    isLoading
    // error
  } = useHttp()

  const handleRows = tempRows => {
    setDataTable({
      ...tableData(type),
      rows: tempRows.data.map(row => ({
        id: row.id,
        nome: row.name || row.nome,
        categoria: row.desccategoria,
        prezzo: `€ ${row.prezzo || row.costo || row.prezzonuovo}`,
        prezzorifacimento: `€ ${row.prezzorifacimento}`,
        descrizione: row.description || row.descrizione,
        link: row.link,
        tag: row.tags?.map(tag => tag.descrizione)?.join(', '),
        azione: {
          action: () => {
            navigate(`/gestione/${section}/${row.id}`)
          }
        }
      }))
    })
  }

  // const filterTable = tipo => {
  //   sendSkillsRequest({
  //     url: '/sitemeup/freelanceskills',
  //     manageData: handleRows
  //   }).then(() => {
  //     setRows(old =>
  //       old.filter(fil => {
  //         console.log(fil, 'hhhh')
  //         return fil.tipologia === tipo
  //       })
  //     )
  //   })
  // }

  useEffect(() => {
    if (url) sendRequest({ url, manageData: handleRows })
  }, [url])

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={2}>
        <Grid container alignItems="center">
          <Grid item xs={12} md={7}>
            <MDBox mb={1}>
              <MDTypography variant="h5">
                {title}
                <Link to={{ pathname: `/gestione/${createLink}` }}>
                  <MDButton variant="gradient" color="info" sx={{ m: 2 }}>
                    <Icon>add</Icon>&nbsp;{btnText}
                  </MDButton>
                </Link>
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid container spacing={2} mb={2}>
            <Grid item xs={12} lg={12}>
              <Divider />
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={2} mb={2}>
          {/* <Grid item xs={12} lg={3}>
            <Card>
              <Sidenav action={filterTable} />
            </Card>
          </Grid> */}
          <Grid item xs={12} lg={12}>
            <Card>
              <LoadingWrapper loading={isLoading}>
                <DataTable table={dataTable} canSearch />
              </LoadingWrapper>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  )
}

export default ArchiveSmu
