import React, { useState } from 'react'

const ErrContext = React.createContext({
  errorText: '',
  sendError: () => {}
})

export const ErrContextProvider = ({ children }) => {
  const [errorText, setErrorText] = useState('')
  const errorTextHandler = text => {
    setErrorText(text)
  }

  const contextValue = {
    errorText,
    sendError: errorTextHandler
  }

  return (
    <ErrContext.Provider value={contextValue}>{children}</ErrContext.Provider>
  )
}

export default ErrContext
