// @mui material components
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
// import DeleteIcon from '@mui/icons-material/Delete'
// import Switch from '@mui/material/Switch'
// import FormControlLabel from '@mui/material/FormControlLabel'
// import Autocomplete from '@mui/material/Autocomplete'
// import TextField from '@mui/material/TextField'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import Chip from '@mui/material/Chip'
import MenuItem from '@mui/material/MenuItem'
import Box from '@mui/material/Box'
import OutlinedInput from '@mui/material/OutlinedInput'

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDEditor from 'components/MDEditor'
import MDButton from 'components/MDButton'
// import MDDropzone from 'components/MDDropzone'

// NewProduct page components
import FormField from 'layouts/ecommerce/products/edit-product/components/FormField'
import { useTheme } from '@emotion/react'
import { Icon } from '@mui/material'

function capitalise(str) {
  return str.replace(/^./, char => char.toUpperCase())
}

function getStyles(item, selectedItems, theme) {
  return {
    fontWeight:
      selectedItems.indexOf(item) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium
  }
}

function Info({
  type,
  name,
  nameHandler,
  description,
  descriptionHandler,
  documentationLink,
  documentationLinkHandler,
  compatibility,
  compatibilityHandler,
  techDescription,
  techDescriptionHandler,
  link,
  linkHandler,
  demoLink,
  demoLinkHandler,
  envatoId,
  envatoIdHandler,
  tags,
  tagsHandler,
  allTags,
  packageTemplate,
  newPackageTemplate,
  packageHandler
}) {
  const theme = useTheme()
  // console.log('INFO', type, name, description, documentationLink, allTags)
  return (
    <>
      <Card>
        <MDBox p={3}>
          <MDTypography variant="h5">
            Informazioni generali - {name || `${capitalise(type)}`}
          </MDTypography>
          <MDBox mt={1}>
            <FormField
              value={name}
              type="text"
              label={`Nome ${capitalise(type)}`}
              onChange={nameHandler}
            />
          </MDBox>
          <MDBox mt={2}>
            {type !== 'template' ? (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <MDTypography
                    component="label"
                    variant="h6"
                    fontWeight="bold"
                  >
                    Descrivi la {type === 'skill' ? 'skill' : 'tipologia'}
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <MDEditor
                    value={description || ''}
                    onChange={descriptionHandler}
                  />
                </Grid>
              </Grid>
            ) : (
              <Grid
                container
                spacing={2}
                display={type === 'template' ? 'block' : 'none'}
              >
                <Grid item xs={12} sm={12}>
                  <FormField
                    value={description}
                    type="text"
                    label={`Descrivi il ${capitalise(type)}`}
                    onChange={descriptionHandler}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <MDTypography
                    component="label"
                    variant="h6"
                    fontWeight="bold"
                  >
                    Descrivi la compatibilit&agrave;
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <MDEditor
                    value={compatibility || ''}
                    onChange={compatibilityHandler}
                  />
                </Grid>
              </Grid>
            )}
          </MDBox>
          {type === 'template' && (
            <MDBox mt={3}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12}>
                  <FormField
                    value={techDescription}
                    type="text"
                    label="Dettagli tecnici"
                    onChange={techDescriptionHandler}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormField
                    value={link}
                    type="text"
                    label="Link"
                    onChange={linkHandler}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormField
                    value={demoLink}
                    type="text"
                    label="Link Demo"
                    onChange={demoLinkHandler}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormField
                    value={envatoId}
                    type="text"
                    label="Envato ID"
                    onChange={envatoIdHandler}
                  />
                </Grid>
                <Grid item>
                  <MDButton
                    variant="gradient"
                    color="info"
                    onClick={() => {
                      const dLink = document.createElement('a')
                      dLink.download = `${name}.zip`
                      dLink.href = packageTemplate.replace('http', 'https')
                      dLink.click()
                    }}
                  >
                    Scarica Pacchetto
                  </MDButton>
                </Grid>
                <Grid item>
                  <label htmlFor="upload-file">
                    <input
                      accept="@file/zip"
                      hidden
                      type="file"
                      id="upload-file"
                      name="upload-file"
                      onChange={e => packageHandler(e.target.files)}
                    />
                    <MDButton variant="gradient" component="span" color="info">
                      Aggiorna Pacchetto
                    </MDButton>
                  </label>
                </Grid>
                <Grid item>{newPackageTemplate && <Icon>check</Icon>}</Grid>
                <Grid item xs={12} sm={12}>
                  <InputLabel id="multiple-tags-label">Tags</InputLabel>
                  <Select
                    labelId="multiple-tags-label"
                    id="multiple-tags"
                    multiple
                    value={tags.map(tag => tag.descrizione)}
                    onChange={event => {
                      const newTagNames = event.target.value
                      tagsHandler(
                        allTags
                          .filter(
                            tag => newTagNames.indexOf(tag.description) > -1
                          )
                          .map(tag => ({ ...tag, id_tag: tag.id }))
                      )
                    }}
                    input={<OutlinedInput label="Chip" />}
                    renderValue={selected => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map(value => (
                          <Chip key={value} label={value} />
                        ))}
                      </Box>
                    )}
                  >
                    {allTags.map(tag => (
                      <MenuItem
                        key={tag.id}
                        value={tag.description}
                        style={getStyles(tag, allTags, theme)}
                      >
                        {tag.description}
                      </MenuItem>
                    ))}
                  </Select>
                  {/* <Autocomplete
                    multiple
                    options={allTags}
                    value={tags.map(tag => tag.descrizione)}
                    freeSolo
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={option}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="standard"
                        label="Tag"
                        placeholder="Crea un nuovo tag"
                      />
                    )}
                    onChange={(event, data) => {
                      console.log('MULTIAUTO', event, data)
                    }}
                  /> */}
                </Grid>
              </Grid>
            </MDBox>
          )}
          {type === 'skill' && (
            <MDBox mt={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <FormField
                    value={documentationLink}
                    type="text"
                    label="Link Documentazione"
                    onChange={documentationLinkHandler}
                  />
                </Grid>
              </Grid>
            </MDBox>
          )}
        </MDBox>
      </Card>
    </>
  )
}

export default Info
