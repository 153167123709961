// ProductsList page components
import MDBadge from 'components/MDBadge'
import Icon from '@mui/material/Icon'

import MDButton from 'components/MDButton'

const nameColumn = { Header: 'nome', accessor: 'nome' }

const actionColumn = {
  Header: 'azione',
  accessor: 'azione',
  Cell: ({ value }) => {
    if (Object.prototype.hasOwnProperty.call(value, 'icon')) {
      return (
        <MDButton
          onClick={() => {
            value.action()
          }}
          color={value.color}
          disabled={value.isDisabled}
        >
          <Icon>{value.icon}</Icon>
        </MDButton>
      )
    }
    return (
      <MDButton
        onClick={() => {
          value.action()
        }}
        color="error"
      >
        <Icon>edit</Icon>
      </MDButton>
    )
  }
}

const tableData = type => {
  if (type === 'skill') {
    return {
      columns: [
        nameColumn,
        {
          Header: 'categoria',
          accessor: 'categoria',
          Cell: ({ value }) => <MDBadge badgeContent={value} color="success" />
        },
        // { Header: 'prezzo', accessor: 'prezzo' },
        actionColumn
      ]
    }
  }

  if (type === 'freelance') {
    return {
      columns: [
        nameColumn,
        { Header: 'descrizione', accessor: 'descrizione' },
        actionColumn
      ]
    }
  }

  if (type === 'progetto') {
    return {
      columns: [
        nameColumn,
        { Header: 'descrizione', accessor: 'descrizione' },
        { Header: 'prezzo nuovo', accessor: 'prezzo' },
        { Header: 'prezzo rifacimento', accessor: 'prezzorifacimento' },
        actionColumn
      ]
    }
  }

  if (type === 'template') {
    return {
      columns: [
        nameColumn,
        { Header: 'descrizione', accessor: 'descrizione' },
        { Header: 'tag', accessor: 'tag' },
        { Header: 'link', accessor: 'link' },
        { Header: 'prezzo', accessor: 'prezzo' },
        actionColumn
      ]
    }
  }

  if (type === 'allFreelances') {
    return {
      columns: [
        nameColumn,
        { Header: 'cognome', accessor: 'cognome' },
        { Header: 'email', accessor: 'email' },
        actionColumn
      ]
    }
  }

  if (type === 'allClienti') {
    return {
      columns: [
        nameColumn,
        { Header: 'cognome', accessor: 'cognome' },
        { Header: 'email', accessor: 'email' }
      ]
    }
  }

  return {
    columns: [nameColumn, actionColumn]
  }
}

export default tableData
