import React, { useState } from 'react'
// import jwtDecode from 'jwt-decode'

const AuthContext = React.createContext({
  token: '',
  session_token: '',
  profiletype: '',
  configurations: [{}],
  isLoggedIn: false,
  // eslint-disable-next-line
  login: (token, sessionToken) => { },
  // eslint-disable-next-line
  setprofile: (profiletype) => { },
  setidoperatore: () => {},
  setidtouroperator: () => {},
  setslugcampo: () => {},
  logout: () => {}
})

export const AuthContextProvider = ({ children }) => {
  const initialToken = localStorage.getItem('token')
  const initialTypeUser = localStorage.getItem('type_user')
  const [token, setToken] = useState(initialToken)
  // const initialsessionToken = localStorage.getItem('session_token')
  // eslint-disable-next-line
  // const [sessiontoken, setSessionToken] = useState(initialsessionToken);
  // eslint-disable-next-line
  const [profiletype, setProfiletype] = useState();
  const [typeUser] = useState(initialTypeUser)
  const userIsLoggedIn = !!token

  const loginHandler = tokenL => {
    // const decode = jwtDecode(tokenL);
    // console.log(tokenL, decode, "aaaa0");
    setToken(tokenL)
    localStorage.setItem('token', tokenL)
    // setSessionToken(sessionToken)
    // localStorage.setItem("token", tokenL);
    // localStorage.setItem('session_token', sessionToken)
    // localStorage.setItem("type_user", decode.type);
    // setTypeUser(decode.type);
  }
  const profileHandler = idprofile => {
    setProfiletype(idprofile)
    localStorage.setItem('idprofile', idprofile)
  }

  const logoutHandler = () => {
    setToken(null)
    // setSessionToken(null)
    localStorage.clear()
  }
  const contextValue = {
    token,
    typeUser,
    isLoggedIn: userIsLoggedIn,
    login: loginHandler,
    setProfile: profileHandler,
    logout: logoutHandler
  }

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  )
}
export default AuthContext
