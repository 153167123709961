const removeNonIntIds = obj => {
  let newObj = Number.isInteger(obj.id) ? obj : { ...obj, id: '' }
  if (Object.prototype.hasOwnProperty.call(newObj, 'id_page')) {
    newObj = Number.isInteger(newObj.id_page)
      ? newObj
      : { ...newObj, id_page: '' }
  }
  if (Object.prototype.hasOwnProperty.call(newObj, 'id_question')) {
    newObj = Number.isInteger(newObj.id_question)
      ? newObj
      : { ...newObj, id_question: '' }
  }
  return newObj
}

const prepImage = image =>
  image && image.includes('base64') ? image.replace(/data:.+,/, '') : ''

const remSpaces = str => str.replace(/\s+/g, '')

const feedback = response => {
  if (response.status === 'OK') {
    // eslint-disable-next-line no-alert
    window.alert(response.message)
  } else {
    // eslint-disable-next-line no-alert
    window.alert(`Errore: ${response.message}`)
  }
}

export { removeNonIntIds, prepImage, remSpaces, feedback }
