// @mui material components
// import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
// import { Dropzone, FileItem } from '@dropzone-ui/react'
// import Icon from '@mui/material/Icon'
import FormField from 'layouts/ecommerce/products/edit-product/components/FormField'

// Material Dashboard 2 PRO React components
// import MDBox from 'components/MDBox'
// import MDTypography from 'components/MDTypography'
// import MDDropzone from 'components/MDDropzone'
// import GestUploadImg from 'pagesmu/CustomComponents/GestUploadImg/GestUploadImg'
// import { useEffect } from 'react'
import ImageGallery from 'pagesmu/CustomComponents/ImageGallery'
import MDButton from 'components/MDButton'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import MDBox from 'components/MDBox'
import MDInput from 'components/MDInput'

const RepeaterPage = ({
  pages = [
    {
      id: 0,
      title: '',
      description: '',
      ordinamento: 0,
      hasDuplicateOrder: false,
      id_tipoprogetto: null,
      images: []
    }
  ],
  orderDuplicates
}) =>
  pages.map(page => (
    <Grid key={page.id} xs={12} sm={6} lg={4} item container spacing={1} mb={3}>
      <Grid item xs={9}>
        <FormField
          className="form-control"
          size="small"
          type="text"
          label="Titolo"
          value={page.title}
          onChange={e => page.pageTitleHandler(e.target.value, page.id)}
          style={{ width: '100%' }}
        />
      </Grid>
      <Grid item xs={3}>
        <MDBox mb={2}>
          <MDInput
            className="form-control"
            value={page.ordinamento}
            label="Ordine"
            variant="standard"
            fullWidth
            size="small"
            type="number"
            onChange={e => page.pageOrderHandler(e.target.value, page.id)}
            error={orderDuplicates.includes(page.ordinamento)}
            inputProps={{ min: 1 }}
          />
        </MDBox>
      </Grid>
      <Grid item xs={12}>
        <FormField
          className="form-control"
          size="small"
          type="text"
          label="Descrizione"
          value={page.description}
          onChange={e => page.pageDescriptionHandler(e.target.value, page.id)}
          style={{ width: '100%' }}
        />
      </Grid>
      <Grid item xs={12}>
        <InputLabel id="project-type-label">Tipo Progetto</InputLabel>
        <Select
          sx={{ mt: 1, width: '100%' }}
          labelId="project-type-label"
          id="project-type"
          value={page.id_tipoprogetto}
          onChange={e => page.projectTypeHandler(e.target.value, page.id)}
        >
          {page.allProjects?.map(proj => (
            <MenuItem key={proj.id} value={proj.id}>
              {proj.nome}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={12}>
        <ImageGallery
          images={page.images}
          imageHandler={page.imageHandler}
          galleryRemoveHandler={page.galleryRemoveHandler}
          pageId={page.id}
        />
      </Grid>
      <Grid item xs={12}>
        <MDButton
          sx={{ width: '100%' }}
          onClick={() => page.pageRemoveHandler(page.id)}
          color="error"
        >
          - Pagina
        </MDButton>
      </Grid>
    </Grid>
    //  <Grid item xs={12} sm={6} lg={4} container spacing={2}>
    //   <MDBox mt={1}>
    //     <MDTypography>Nuova pagina</MDTypography>
    //     <Grid item xs={12}>
    //       <FormField
    //         className="form-control"
    //         size="small"
    //         type="text"
    //         label="Titolo"
    //         value=""
    //         onChange={e =>
    //           pageTitleHandler(e.target.value, `${pages.length + 1}pp`)
    //         }
    //         style={{ width: '100%' }}
    //       />
    //     </Grid>
    //     <Grid item xs={12}>
    //       <FormField
    //         className="form-control"
    //         size="small"
    //         type="text"
    //         label="Descrizione"
    //         value=""
    //         onChange={e =>
    //           pageDescriptionHandler(e.target.value, `${pages.length + 1}pp`)
    //         }
    //         style={{ width: '100%' }}
    //       />
    //     </Grid>
    //     <Grid item xs={12}>
    //       <ImageGallery images={pages.images} isPages />
    //     </Grid>
    //   </MDBox>
    // </Grid >
  ))

export default RepeaterPage
