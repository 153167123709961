/* eslint-disable no-alert */
import { useState, useEffect } from 'react'

// @mui material components
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'

// Material Dashboard 2 PRO React example components
import DashboardLayout from 'commoncomponent/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'commoncomponent/Navbars/DashboardNavbar'
import Footer from 'commoncomponent/Footer'
import DataTable from 'commoncomponent/Tables/DataTable'

// import Sidenav from 'pagesmu/gestskill/skillarchive/components/Sidenav/SidenavSkill'

import useHttp from 'hooks/use-http'
import tableData from 'pagesmu/CustomComponents/Archive/data/tableData'
import LoadingWrapper from '../LoadingWrapper/LoadingWrapper'

function capitalise(str) {
  return str.replace(/^./, char => char.toUpperCase())
}

function ListSmu({ type }) {
  const [url, setUrl] = useState('')
  const [approveUrl, setApproveUrl] = useState('')
  const [title, setTitle] = useState(`Elenco ${capitalise(type)}`)
  const [dataTable, setDataTable] = useState({
    ...tableData(type),
    rows: []
  })
  const [, setFreelances] = useState([])
  const [, setClienti] = useState([])

  useEffect(() => {
    switch (type) {
      case 'allFreelances':
        setUrl('/sitemeup/freelances')
        setTitle('Elenco anagrafico freelance')
        setApproveUrl('/sitemeup/freelanceapprova')
        break
      case 'allClienti':
        setUrl('/sitemeup/clienti')
        setTitle('Elenco anagrafico clienti')
        break
      default:
        break
    }
  }, [type])

  const {
    sendRequest: sendGetRequest,
    isLoading: isGetLoading
    // error
  } = useHttp()

  const {
    sendRequest: sendApproveRequest,
    isLoading: isApproveLoading
    // error
  } = useHttp()

  const handleFreelanceRows = tempRows => {
    setFreelances(tempRows.data)
    setDataTable({
      ...tableData(type),
      rows: tempRows.data.map(row => ({
        id: row.id,
        nome: row.nome,
        cognome: row.cognome,
        email: row.email,
        azione: {
          action: () => {
            sendApproveRequest({
              url: approveUrl,
              methodH: 'POST',
              bodyH: { id: row.id },
              manageData: res => {
                if (res.status === 'OK') {
                  window.alert(res.message)
                  setFreelances(old =>
                    old.map(freelance =>
                      freelance.id === row.id ? res.data : freelance
                    )
                  )
                } else window.alert(`Errore: ${res.message}`)
              }
            })
          },
          icon: row.approved ? 'check' : 'close',
          color: row.approved ? 'success' : 'error',
          isDisabled: !!row.approved
        }
      }))
    })
  }

  const handleClientiRows = tempRows => {
    setClienti(tempRows.data)
    setDataTable({
      ...tableData(type),
      rows: tempRows.data.map(row => ({
        id: row.id,
        nome: row.nome,
        cognome: row.cognome,
        email: row.email
      }))
    })
  }
  // const filterTable = tipo => {
  //   sendSkillsRequest({
  //     url: '/sitemeup/freelanceskills',
  //     manageData: handleRows
  //   }).then(() => {
  //     setRows(old =>
  //       old.filter(fil => {
  //         console.log(fil, 'hhhh')
  //         return fil.tipologia === tipo
  //       })
  //     )
  //   })
  // }

  useEffect(() => {
    if (url) {
      sendGetRequest({
        url,
        manageData: url.includes('freelances')
          ? handleFreelanceRows
          : handleClientiRows
      })
    }
  }, [url])

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={2}>
        <Grid container alignItems="center">
          <Grid item xs={12} md={7}>
            <MDBox mb={1}>
              <MDTypography variant="h5">{title}</MDTypography>
            </MDBox>
          </Grid>
          <Grid container spacing={2} mb={2}>
            <Grid item xs={12} lg={12}>
              <Divider />
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={2} mb={2}>
          <LoadingWrapper loading={isGetLoading || isApproveLoading}>
            {/* <Grid item xs={12} lg={3}>
            <Card>
              <Sidenav action={filterTable} />
            </Card>
          </Grid> */}
            <Grid item xs={12} lg={12}>
              <Card>
                <DataTable table={dataTable} canSearch />
              </Card>
            </Grid>
          </LoadingWrapper>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  )
}

export default ListSmu
