/* eslint-disable prettier/prettier */
// import React, { useState, useEffect } from 'react'

// @mui material components
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import DeleteIcon from '@mui/icons-material/Delete'
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider'
// import Switch from '@mui/material/Switch'
// import FormControlLabel from '@mui/material/FormControlLabel'
// import Select from '@mui/material/Select'
// import MenuItem from '@mui/material/MenuItem'
import Autocomplete from '@mui/material/Autocomplete'
// import { Dropzone, FileItem } from '@dropzone-ui/react'
import MDDropzone from 'components/MDDropzone'

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDInput from 'components/MDInput'
import MDButton from 'components/MDButton'

// EditProduct page components
import FormField from 'layouts/ecommerce/products/edit-product/components/FormField'
import GestUploadImg from 'pagesmu/CustomComponents/GestUploadImg/GestUploadImg'
import { Link } from 'react-router-dom'

function RightSidebar({
  section,
  price,
  priceHandler,
  priceRe,
  priceReHandler,
  saveHandler,
  categories,
  category,
  categoryHandler,
  image,
  imageHandler,
  imageRemoveHandler,
  deleteItem
}) {
  // const [mainImmage, setMainImmage] = useState()

  // useEffect(() => {
  //   if (images && images.length) {
  //     setMainImmage(images.find(data => data.main === true))
  //   }
  // }, [images])
  let pathname = '/gestione'
  switch (section) {
    case 'freelance':
      pathname += '/tipologiefreelance'
      break
    case 'template':
      pathname += '/templates'
      break
    case 'progetto':
      pathname += '/progetti'
      break
    default:
      pathname += '/skills'
      break
  }
  // console.log('RISB', image?.slice(0, 50), categories, 'cat', category)
  return (
    <Card>
      <MDBox p={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={12}>
            <MDBox display="flex" justifyContent="flex-end">
              <MDButton
                variant="gradient"
                color="info"
                onClick={saveHandler}
                fullWidth
              >
                salva
              </MDButton>
            </MDBox>
          </Grid>
          <Grid item xs={12} lg={12}>
            <MDBox display="flex" justifyContent="space-evenly" flexWrap="wrap">
              <MDButton variant="gradient" color="light" size="large">
                <Link to={{ pathname }} color="light">
                  <CloseIcon />
                </Link>
              </MDButton>
              <MDButton
                variant="gradient"
                color="error"
                size="large"
                onClick={deleteItem}
              >
                <DeleteIcon />
              </MDButton>
            </MDBox>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={12}>
            <Divider />
          </Grid>
        </Grid>
      </MDBox>
      <MDBox p={3}>
        <MDTypography variant="h5" fontWeight="bold">
          Configurazione {section.replace(/^./, x => x.toUpperCase())}
        </MDTypography>
        <MDBox mt={1}>
          <Grid container spacing={2}>
            {price !== undefined ? <Grid item xs={12}>
              <FormField
                type="number"
                value={price}
                onChange={priceHandler}
                label={`Prezzo ${section}${section.includes('pro') ? ' - Nuovo' : ''}`}
              />
            </Grid> : ''}
            {priceRe !== undefined ? <Grid item xs={12}>
              <FormField
                type="number"
                value={priceRe}
                onChange={priceReHandler}
                label={`Prezzo ${section} - Rifacimento`}
              />
            </Grid> : ''}
            {section === 'skill' && (
              <Grid item xs={12}>
                <MDBox my={2} display="inline-block">
                  <MDTypography
                    component="label"
                    variant="h6"
                    fontWeight="bold"
                  >
                    Tipologia {section.replace(/^./, x => x.toUpperCase())}
                  </MDTypography>
                </MDBox>
                {/* <Select
                sx={{ mt: 1, width: '100%' }}
                displayEmpty
                value={category}
                onChange={e => categoryHandler(e.target.value)}
              // renderValue={selected => {
              //   if (selected === undefined || selected === '') {
              //     return 'Nessuna categoria'
              //   }
              //   return selected
              // }}
              >
                <MenuItem value="">Nessuna categoria</MenuItem>
                {categories.map(t => (
                  <MenuItem value={t.value}>{t.label}</MenuItem>
                ))}
              </Select> */}
                <Autocomplete
                  value={
                    category
                      ? {
                        label: categories.find(cat => cat.value === category)
                          ?.label,
                        value: categories.find(cat => cat.value === category)
                          ?.value
                      }
                      : { label: '', value: '' }
                  }
                  options={categories}
                  onChange={(event, data) => {
                    if (data) {
                      categoryHandler(data, '')
                    } else {
                      categoryHandler(data, 'del')
                    }
                  }}
                  renderInput={params => (
                    <MDInput {...params} variant="standard" />
                  )}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <MDBox my={2} display="inline-block">
                {!image ? (
                  <>
                    <br />
                    <MDTypography variant="button">
                      Utilizza una immagine non piu grande di 2Mb
                    </MDTypography>
                    <MDDropzone
                      options={{
                        addRemoveLinks: true,
                        maxFiles: 1,
                        maxFilesize: 2,
                        acceptedFIles: 'image/jpg, image/jpeg, image/png',
                        accept: file => {
                          imageHandler(file)
                        }
                      }}
                    />
                  </>
                ) : (
                  <>
                    <br />
                    <GestUploadImg
                      image={image}
                      imageRemoveHandler={imageRemoveHandler}
                    />
                  </>
                )}
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  )
}

export default RightSidebar
