import MDBox from 'components/MDBox'
import CircularProgress from '@mui/material/CircularProgress'

const LoadingWrapper = ({ children, loading }) => (
  <>
    {loading ? (
      <MDBox width="100%" display="flex" justifyContent="center">
        <CircularProgress />
      </MDBox>
    ) : (
      children
    )}
  </>
)
export default LoadingWrapper
