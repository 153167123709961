// import Card from '@mui/material/Card'

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'

// Images

function GestUploadImg({ image, imageRemoveHandler, isPage }) {
  return (
    // <Card
    //   sx={{
    //     '&:hover .card-header': {
    //       transform: 'translate3d(0, -50px, 0)'
    //     }
    //   }}
    // >
    <>
      <MDBox
        position="relative"
        borderRadius="lg"
        mt={2}
        mx={2}
        className="card-header"
        sx={{ transition: 'transform 300ms cubic-bezier(0.34, 1.61, 0.7, 1)' }}
      >
        <MDBox
          component="img"
          src={image}
          alt="Image"
          borderRadius="lg"
          shadow="sm"
          width="100%"
          height="100%"
          position="relative"
          mb={2}
        />
      </MDBox>
      <MDBox textAlign="center" pt={2} pb={3} px={3}>
        <MDBox
          display="flex"
          justifyContent="center"
          mt={-10}
          position="relative"
          zIndex={10}
        >
          {isPage || (
            <MDButton
              variant="outlined"
              color="error"
              size="small"
              onClick={imageRemoveHandler}
            >
              remove
            </MDButton>
          )}
        </MDBox>
      </MDBox>
    </>
    // </Card>
  )
}

export default GestUploadImg
