// @mui material components
// import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
// import { Dropzone, FileItem } from '@dropzone-ui/react'
// import Icon from '@mui/material/Icon'

// Material Dashboard 2 PRO React components
// import MDBox from 'components/MDBox'
// import MDTypography from 'components/MDTypography'
// import MDDropzone from 'components/MDDropzone'
// import GestUploadImg from 'pagesmu/CustomComponents/GestUploadImg/GestUploadImg'
// import { useEffect } from 'react'
import MDButton from 'components/MDButton'
// import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import MDInput from 'components/MDInput'
import { FormGroup, FormControlLabel, Checkbox } from '@mui/material'

const RepeaterFreelancers = ({
  freelancers = [
    {
      id: 0,
      costonuovo: 0,
      costorifacimento: 0,
      id_skill: 0,
      id_tipofreelance: 0,
      id_tipoprogetto: 0,
      nome: '',
      obbligatorio: 0,
      sceglietemplate: 0,
      questions: []
    }
  ]
}) =>
  freelancers.map(freelancer => (
    <Grid
      key={freelancer.id}
      xs={12}
      sm={6}
      lg={4}
      item
      container
      spacing={1}
      mb={3}
    >
      <Grid item xs={12}>
        Tipologia Freelance
        <div style={{ width: '100%' }}>
          <Select
            sx={{ mt: 1, width: '100%' }}
            value={freelancer.id_tipofreelance}
            onChange={e =>
              freelancer.typeIdHandler(freelancer.id, e.target.value)
            }
          >
            {freelancer.allFreelancersTypes?.map(f => (
              <MenuItem key={f.value} value={f.value}>
                {f.label}
              </MenuItem>
            ))}
          </Select>
        </div>
      </Grid>
      <Grid item xs={6}>
        <MDInput
          className="form-control"
          value={freelancer.costonuovo}
          label="Costo - nuovo"
          variant="standard"
          fullWidth
          size="small"
          type="number"
          onChange={e =>
            freelancer.priceHandler(e.target.value, freelancer.id_tipofreelance)
          }
          inputProps={{ min: 0 }}
        />
      </Grid>
      <Grid item xs={6}>
        <MDInput
          className="form-control"
          value={freelancer.costorifacimento}
          label="Costo - rifacimento"
          variant="standard"
          fullWidth
          size="small"
          type="number"
          onChange={e =>
            freelancer.priceReHandler(
              e.target.value,
              freelancer.id_tipofreelance
            )
          }
          inputProps={{ min: 0 }}
        />
      </Grid>
      <Grid item xs={6}>
        <FormControlLabel
          control={
            <Checkbox
              checked={!!freelancer.obbligatorio}
              onChange={e =>
                freelancer.obbligHandler(
                  e.target.checked,
                  freelancer.id_tipofreelance
                )
              }
            />
          }
          label="Obbligatorio"
          labelPlacement="top"
        />
      </Grid>
      <Grid item xs={6}>
        <FormControlLabel
          control={
            <Checkbox
              checked={!!freelancer.sceglietemplate}
              onChange={e =>
                freelancer.stHandler(
                  e.target.checked,
                  freelancer.id_tipofreelance
                )
              }
            />
          }
          label="Sceglie Template"
          labelPlacement="top"
        />
      </Grid>
      <Grid item xs={12}>
        Domande
        <FormGroup>
          {!!freelancer.id_tipofreelance &&
            freelancer.allFreelancersQuestions.length &&
            freelancer.allFreelancersQuestions
              .flatMap(type =>
                type.id === freelancer.id_tipofreelance ? type.questions : []
              )
              .map(question => (
                <FormControlLabel
                  key={question.id}
                  value={question.id}
                  control={
                    <Checkbox
                      checked={freelancer.questions.includes(question.id)}
                      onChange={e => freelancer.questionsHandler(e)}
                    />
                  }
                  label={question.name}
                  labelPlacement="start"
                />
              ))}
        </FormGroup>
      </Grid>
      <Grid item xs={12}>
        <MDButton
          sx={{ width: '100%' }}
          onClick={() => freelancer.freelancerRemoveHandler(freelancer.id)}
          color="error"
        >
          - Freelancer
        </MDButton>
      </Grid>
    </Grid>
  ))

export default RepeaterFreelancers
