/* eslint-disable prettier/prettier */
import { useState, useCallback, useContext } from 'react'

import ErrContext from 'pagesmu/store/error-context'
// import AuthContext from 'pagesmu/store/auth-context'
import Cookies from 'js-cookie'

const useHttp = () => {
  const { sendError } = useContext(ErrContext)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  // const { token, isLoggedIn } = useContext(AuthContext)

  const [apiURL] = useState(`${process.env.REACT_APP_API_ENDPOINT}`)

  const sendRequest = useCallback(
    async ({ url, methodH, headersH, bodyH, manageData }) => {
      setIsLoading(true)
      setError(null)

      try {
        if (!Cookies.get('XSRF-TOKEN')) {
          // eslint-disable-next-line no-unused-vars
          const csrfRequest = await fetch(`${apiURL}/csrf-cookie`, {
            method: 'GET',
            headers: {
              'content-type': 'application/json',
              accept: 'application/json'
            },
            credentials: 'include'
          })
        }

        const mainHeaderSettings = !localStorage.getItem('token')
          ? {
            'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN') || ''
          }
          : {
            'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN') || '',
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }

        const response = await fetch(apiURL + url, {
          method: methodH || 'GET',
          headers: headersH
            ? {
              ...mainHeaderSettings,
              ...headersH
            }
            : {
              ...mainHeaderSettings,
              'content-type': 'application/json',
              accept: 'application/json'
              // ...(token && { Authorization: `Bearer ${token}` })
            },
          body: JSON.stringify(bodyH) || null,
          credentials: 'include'
        })

        const tempJson = await response.json()

        // eslint-disable-next-line no-console
        console.log('INVOKEAPI', {
          path: url,
          request: {
            method: methodH || 'GET',
            headers: headersH
              ? {
                ...mainHeaderSettings,
                ...headersH
              }
              : {
                ...mainHeaderSettings,
                'content-type': 'application/json',
                accept: 'application/json'
                // ...(token && { Authorization: `Bearer ${token}` })
              },
            body: JSON.stringify(bodyH) || null,
            credentials: 'include'
          },
          response: tempJson
        })

        if (!response.ok) {
          response.text().then(text => {
            // eslint-disable-next-line no-console
            console.error('ERRORE', text, url)
            sendError(text)
            throw new Error(text)
          })
        }
        manageData(tempJson)
        setIsLoading(false)
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('ERRORE THROWATO 2', err, url)
        sendError(err);
      }
    }
  )

  return { isLoading, error, sendRequest }
}
export default useHttp
