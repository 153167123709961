import React, { useState, useEffect, useCallback } from 'react'

import useHttp from 'hooks/use-http'

const TestPage = () => {
  const [picks, setPicks] = useState([])
  const picksSetter = useCallback(
    picArray => {
      setPicks(picArray)
    },
    [setPicks]
  )

  const fetchPicksConfig = {
    url: 'https://jsonplaceholder.typicode.com/photos?_limit=10',
    manageData: picksSetter
  }

  const { sendRequest: fetchPicks, isLoading } = useHttp({
    manageData: setPicks
  })

  useEffect(() => {
    fetchPicks(fetchPicksConfig)
  }, [])

  const removePic = id => setPicks(old => old.filter(fil => fil.id !== id))

  return (
    <>
      <div className="d-flex justify-content-center flex-column align-items-center">
        {!isLoading && (
          <button
            type="button"
            onClick={() => {
              fetchPicks(fetchPicksConfig)
            }}
          >
            {' '}
            Ripristina{' '}
          </button>
        )}
        {!isLoading &&
          picks.map(pic => (
            <div>
              <img src={pic.url} alt="pic" />
              <button
                type="button"
                onClick={() => {
                  removePic(pic.id)
                }}
              >
                RIMUOVI
              </button>
            </div>
          ))}
      </div>
    </>
  )
}

export default TestPage
