import { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

// @mui material components
import Grid from '@mui/material/Grid'

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'

// Material Dashboard 2 PRO React example components
import DashboardLayout from 'commoncomponent/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'commoncomponent/Navbars/DashboardNavbar'
import Footer from 'commoncomponent/Footer'

// Custom Hooks
import useHttp from 'hooks/use-http'
// EditSkill page components
// import SkillImage from 'pagesmu/gestskill/skilldetail/components/SkillImage/SkillImage'
import Info from 'pagesmu/CustomComponents/Info'
import RightSidebar from 'pagesmu/CustomComponents/RightSidebar'
import MDButton from 'components/MDButton'
import LoadingWrapper from 'pagesmu/CustomComponents/LoadingWrapper/LoadingWrapper'

import CustomRepeater from 'pagesmu/CustomComponents/Repeaters/Repeater'
import { removeNonIntIds, prepImage, feedback } from '../../utils/utils'

function EditSkillSmu() {
  const { id: pId } = useParams()

  const [nameSkill, setNameSkill] = useState('')
  const [descriptionSkill, setDescriptionSkill] = useState('')
  const [imageSkill, setImageSkill] = useState('')
  const [priceSkill, setPriceSkill] = useState(0)
  const [categorySkill, setCategorySkill] = useState()
  const [categories, setCategories] = useState([])
  const [imageExtensionSkill, setImageExtensionSkill] = useState('')
  const [documentationLinkSkill, setDocumentationLinkSkill] = useState('')
  const questionTypes = [
    { label: '(Scegli)', value: 0 },
    { label: 'Testo libero', value: 1 },
    { label: 'Risposta multipla', value: 3 }
  ]
  const boolTypes = [
    { label: '(Scegli)', value: -1 },
    { label: 'No', value: 0 },
    { label: 'Si', value: 1 }
  ]
  const toAddressToTypes = [
    { label: '(Scegli)', value: 0 },
    { label: 'Freelance', value: 1 },
    { label: 'Ordine Cliente', value: 2 }
  ]

  const defaultQuestion = {
    id: '1prov',
    text: '',
    id_question_type: 0,
    mandatory: -1,
    to_address_to: 0,
    answers: []
  }
  const [repeaterQuestions, setRepeaterQuestions] = useState([defaultQuestion])

  const repeaterQuestionsAddRowHandler = () => {
    setRepeaterQuestions(old => [
      ...old,
      {
        ...defaultQuestion,
        id: `${old?.length + 1}prov`
      }
    ])
  }

  const repeaterAnswersAddRowHandler = id => () => {
    setRepeaterQuestions(old =>
      old.map(q =>
        q.id === id
          ? {
              ...q,
              answers: [
                ...q.answers,
                {
                  key: '',
                  value: '',
                  id_question: id,
                  id: `${q.answers.length + 1}ans`
                }
              ]
            }
          : q
      )
    )
  }

  const repeaterQuestionsHandler = param => (value, id) => {
    setRepeaterQuestions(old =>
      old.map(r => (r.id === id ? { ...r, [param]: value } : r))
    )
  }

  const repeaterAnswersHandler = param => (value, answerId, questionId) => {
    setRepeaterQuestions(old =>
      old.map(r =>
        r.id === questionId
          ? {
              ...r,
              answers: r.answers.map(a =>
                a.id === answerId ? { ...a, [param]: value } : a
              )
            }
          : r
      )
    )
  }

  const repeaterQuestionsRemoveRowHandler = id => () => {
    setRepeaterQuestions(old => old.filter(rq => rq.id !== id))
  }

  const repeaterAnswersRemoveRowHandler =
    ({ questionId, answerId }) =>
    () => {
      setRepeaterQuestions(old =>
        old.map(rq =>
          rq.id === questionId
            ? { ...rq, answers: rq.answers.filter(ra => ra.id !== answerId) }
            : rq
        )
      )
    }

  // Category e Sub Category
  const {
    sendRequest: sendCategoriesRequest,
    isLoading: isCategoriesLoading
    // error: categoriesError
  } = useHttp()

  const manageCategories = res => {
    setCategories(res?.data)
  }

  useEffect(() => {
    sendCategoriesRequest({
      url: '/sitemeup/skillscategories',
      manageData: manageCategories
    })
  }, [])

  const categoryHandler = (data, op) => {
    if (op === 'del') {
      setCategorySkill(null)
    } else {
      setCategorySkill(data.value)
    }
  }
  // Fine Category e Sub Category

  // Images
  const imageHandler = image => {
    // const mime = image.type
    const reader = new FileReader()
    reader.readAsDataURL(image)
    reader.onload = e => {
      // const mime = e.target.result.match(/^data:(.*);/)[1]
      // const base64 = e.target.result.replace(`data:${mime};base64,`, '')
      const base64 = e.target.result
      // console.log('MAINIMAGE', base64)
      setImageSkill(base64)
      setImageExtensionSkill(image.type.split('/')[1])
    }
  }

  const imageRemoveHandler = () => {
    setImageSkill('')
    setImageExtensionSkill('')
  }

  // Fine images

  const {
    sendRequest: sendDelSkillRequest,
    isLoading: isDelSkillLoading
    // error: delSkillError
  } = useHttp()

  const navigate = useNavigate()

  const deleteSkill = () => {
    // eslint-disable-next-line no-alert
    if (window.confirm('Sei sicuro di voler eliminare questa Skill?')) {
      sendDelSkillRequest({
        methodH: 'DELETE',
        url: '/sitemeup/freelanceskillsdelete',
        bodyH: { id: pId },
        manageData: () => navigate('/gestione/skills')
      })
    }
  }

  const {
    sendRequest: sendSkillRequest,
    isLoading: isSkillLoading
    // error: skillError
  } = useHttp()

  const manageSkillGet = ({
    description: descriptionG,
    name: nameG,
    documentazione: documentazioneG,
    prezzo: prezzoG,
    imagepath: imagepathG,
    id_category: idCategoryG,
    questions: questionsG
  }) => {
    setNameSkill(nameG)
    setDescriptionSkill(descriptionG)
    setDocumentationLinkSkill(documentazioneG)
    setPriceSkill(prezzoG)
    setImageSkill(imagepathG)
    setCategorySkill(idCategoryG)
    setRepeaterQuestions(questionsG)
  }

  useEffect(() => {
    if (pId) {
      sendSkillRequest({
        url: `/sitemeup/freelanceskill?id=${pId}`,
        manageData: ({ data }) => {
          manageSkillGet(data)
        }
      })
    }
  }, [pId])

  const nameHandler = e => {
    setNameSkill(e.target.value || ' ')
  }

  const documentationLinkSkillHandler = e => {
    setDocumentationLinkSkill(e.target.value)
  }

  const descriptionHandler = e => {
    setDescriptionSkill(e)
  }

  const priceHandler = e => {
    setPriceSkill(parseFloat(e.target.value))
  }

  const {
    sendRequest: sendSaveRequest,
    isLoading: isSaveLoading
    // error: saveError
  } = useHttp()

  const saveHandler = () => {
    const data = {
      id: pId,
      id_category: categorySkill,
      description: descriptionSkill,
      name: nameSkill,
      estensioneimmagine: imageExtensionSkill,
      documentazione: documentationLinkSkill,
      image: prepImage(imageSkill),
      prezzo: priceSkill,
      questions: repeaterQuestions.map(removeNonIntIds).map(question => ({
        ...question,
        answers: question.answers.map(removeNonIntIds)
      }))
      // questions: repeaterQuestions.map(rq => ({
      //   ...rq,
      //   id: parseInt(rq.id, 10),
      //   answers: rq.answers.map(ra => ({
      //     ...ra,
      //     id: parseInt(ra.id, 10),
      //     id_question: parseInt(ra.id_question, 10)
      //   }))
      // }))
    }
    sendSaveRequest({
      methodH: pId ? 'POST' : 'PUT',
      url: pId
        ? '/sitemeup/freelanceskillsupdate'
        : '/sitemeup/freelanceskillsave',
      bodyH: data,
      manageData: feedback
    })
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={9}>
            <MDBox mb={3}>
              <Grid container spacing={3}>
                <LoadingWrapper
                  loading={
                    isSkillLoading ||
                    isCategoriesLoading ||
                    isDelSkillLoading ||
                    isSaveLoading
                  }
                >
                  <Grid item xs={12}>
                    <Info
                      type="skill"
                      name={nameSkill}
                      nameHandler={nameHandler}
                      description={descriptionSkill}
                      descriptionHandler={descriptionHandler}
                      documentationLink={documentationLinkSkill}
                      documentationLinkHandler={documentationLinkSkillHandler}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container rowSpacing={2}>
                      <CustomRepeater
                        elements={repeaterQuestions.map(q => ({
                          ...q,
                          isSkill: true,
                          width: 4,
                          type: q.id_question_type,
                          types: questionTypes,
                          isMandatory: q.mandatory,
                          boolTypes,
                          toAddressTo: q.to_address_to,
                          toAddressToTypes,
                          value: q.text,
                          multiAnswers: q.answers,
                          isMulti: q.id_question_type === 3,
                          textHandler: repeaterQuestionsHandler('text'),
                          typeHandler:
                            repeaterQuestionsHandler('id_question_type'),
                          mandatoryHandler:
                            repeaterQuestionsHandler('mandatory'),
                          toAddressToHandler:
                            repeaterQuestionsHandler('to_address_to'),
                          repeaterAnswersKeyHandler:
                            repeaterAnswersHandler('key'),
                          repeaterAnswersValueHandler:
                            repeaterAnswersHandler('value'),
                          removeRowHandler: repeaterQuestionsRemoveRowHandler,
                          repeaterAnswersAddRowHandler,
                          repeaterAnswersRemoveRowHandler
                        }))}
                      />
                      <Grid item>
                        <MDButton
                          onClick={() => repeaterQuestionsAddRowHandler()}
                          variant="gradient"
                          color="info"
                        >
                          + Domanda
                        </MDButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </LoadingWrapper>
              </Grid>
            </MDBox>
          </Grid>
          <Grid item xs={12} lg={3}>
            <RightSidebar
              section="skill"
              priceHandler={priceHandler}
              saveHandler={saveHandler}
              image={imageSkill}
              imageHandler={imageHandler}
              imageRemoveHandler={imageRemoveHandler}
              categories={categories.map(cat => ({
                label: cat.name,
                value: cat.id
              }))}
              category={categorySkill}
              categoryHandler={categoryHandler}
              deleteItem={deleteSkill}
            />
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  )
}

export default EditSkillSmu
