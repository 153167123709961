/* eslint-disable no-alert */
import { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

// @mui material components
import Grid from '@mui/material/Grid'

// Material Dashboard 2 PRO React components
// import MDButton from 'components/MDButton'
import MDBox from 'components/MDBox'
import Card from '@mui/material/Card'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'
import LoadingWrapper from 'pagesmu/CustomComponents/LoadingWrapper/LoadingWrapper'

// Material Dashboard 2 PRO React example components
import DashboardLayout from 'commoncomponent/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'commoncomponent/Navbars/DashboardNavbar'
import Footer from 'commoncomponent/Footer'

// Custom Hooks
import useHttp from 'hooks/use-http'

// EditSkill page components
import Info from 'pagesmu/CustomComponents/Info'
import RightSidebar from 'pagesmu/CustomComponents/RightSidebar'

import RepeaterFreelancers from 'pagesmu/CustomComponents/Repeaters/RepeaterFreelancers'

import { removeNonIntIds, prepImage, remSpaces } from '../../utils/utils'

function EditProjectSmu() {
  const { id: pId } = useParams()

  const [nameProject, setNameProject] = useState('')
  const [descriptionProject, setDescriptionProject] = useState('')
  const [imageProject, setImageProject] = useState('')
  const [imageExtensionProject, setImageExtensionProject] = useState('')
  const [imageFileNameProject, setImageFileNameProject] = useState('')
  const [priceProject, setPriceProject] = useState(0)
  const [priceReProject, setPriceReProject] = useState(0)
  const [freelancerTypesProject, setFreelancerTypesProject] = useState([])
  const [freelancerQuestions, setFreelancerQuestions] = useState([])
  const [allFreelancersTypes, setAllFreelancersTypes] = useState([])
  const [allFreelancersQuestions, setAllFreelancersQuestions] = useState([])

  const {
    sendRequest: sendAllFreelancersProjectRequest,
    isLoading: isAllFreelancersLoading
    // error: delSkillError
  } = useHttp()

  const manageAllFreelancers = data => {
    setAllFreelancersTypes(
      data.map(fl => ({
        value: fl.id,
        label: fl.name
      }))
    )
    setAllFreelancersQuestions(
      data.map(fl => ({
        id: fl.id,
        questions: Object.values(fl.questions)
          .flat()
          .map(question => ({
            id: question.id,
            name: question.text
          }))
      }))
    )
  }

  useEffect(() => {
    sendAllFreelancersProjectRequest({
      url: '/sitemeup/tipologiefreelance',
      manageData: async ({ data }) => manageAllFreelancers(data)
    })
  }, [])

  const {
    sendRequest: sendSaveRequest,
    isLoading: isSaveLoading
    // error: errorSave
  } = useHttp()

  const manageProjectGet = ({
    nome: name,
    descrizione: description,
    nomefileimmagine: imageFileName,
    imagepath: imagePath,
    estensione: imageExtension,
    prezzonuovo: priceNew,
    prezzorifacimento: priceRework,
    tipifreelance: freelancerTypes
  }) => {
    setNameProject(name)
    setDescriptionProject(description)
    setImageProject(imagePath)
    setImageExtensionProject(imageExtension)
    setImageFileNameProject(imageFileName)
    setPriceProject(priceNew)
    setPriceReProject(priceRework)
    setFreelancerTypesProject(freelancerTypes)
    setFreelancerQuestions(
      freelancerTypes
        .map(ft => Object.values(ft.questions))
        .flat(2)
        .map(fq => fq.id_domanda)
    )
  }

  const {
    sendRequest: sendProjectRequest,
    isLoading: isProjectLoading
    // error: errorProject
  } = useHttp()

  useEffect(() => {
    if (pId) {
      sendProjectRequest({
        url: `/sitemeup/tipologiaprogetto?id=${pId}`,
        manageData: ({ data }) => {
          manageProjectGet(data)
        }
      })
    }
  }, [pId])

  const nameHandler = e => {
    setNameProject(e.target.value)
  }

  const descriptionHandler = e => {
    setDescriptionProject(e)
  }

  const priceHandler = e => {
    setPriceProject(e.target.value)
  }

  const priceReHandler = e => {
    setPriceReProject(e.target.value)
  }

  const typeIdHandler = (id, e) => {
    setFreelancerTypesProject(old =>
      old.map(f => (f.id === id ? { ...f, id_tipofreelance: e } : f))
    )
  }

  const freelancerParamHandler = param => (value, id) => {
    setFreelancerTypesProject(old =>
      old.map(frType => {
        if (frType.id_tipofreelance === id) {
          if (param.includes('costo')) {
            return { ...frType, [param]: value }
          }
          return { ...frType, [param]: value ? 1 : 0 }
        }
        return frType
      })
    )
  }

  const questionsHandler = e => {
    setFreelancerQuestions(old =>
      e.target.checked
        ? [...old, parseInt(e.target.value, 10)]
        : old.filter(o => o !== parseInt(e.target.value, 10))
    )
  }

  const imageHandler = image => {
    const reader = new FileReader()
    reader.readAsDataURL(image)
    reader.onload = e => {
      const base64 = e.target.result
      setImageProject(base64)
      setImageFileNameProject(`${remSpaces(nameProject)}-image`)
      setImageExtensionProject(image.type.split('/')[1])
    }
  }

  const imageRemoveHandler = () => {
    setImageProject('')
    setImageExtensionProject('')
  }

  const addFreelancerHandler = () => {
    setFreelancerTypesProject(old => [
      ...old,
      {
        id: `${old?.length + 1}prov`,
        costonuovo: 0,
        costorifacimento: 0,
        id_tipofreelance: 0,
        id_tipoprogetto: pId,
        nome: '',
        obbligatorio: 0,
        sceglietemplate: 0,
        questions: []
      }
    ])
  }

  const freelancerRemoveHandler = id => {
    if (window.confirm('Sei sicuro di voler eliminare questo freelancer?'))
      setFreelancerTypesProject(old => old.filter(fl => fl.id !== id))
  }

  const {
    sendRequest: sendDelProjectRequest,
    isLoading: isDelProjectLoading
    // error: delSkillError
  } = useHttp()

  const navigate = useNavigate()

  const deleteProject = () => {
    // eslint-disable-next-line no-alert
    if (window.confirm('Sei sicuro di voler eliminare questo Progetto?')) {
      sendDelProjectRequest({
        methodH: 'DELETE',
        url: '/sitemeup/tipologiaprogettodelete',
        bodyH: { id: pId },
        manageData: () => navigate('/gestione/progetti')
      })
    }
  }

  const saveHandler = () => {
    const data = {
      id: parseInt(pId, 10),
      descrizione: descriptionProject,
      nome: nameProject,
      estensione: imageExtensionProject,
      image: prepImage(imageProject),
      nomefileimmagine: imageFileNameProject,
      prezzonuovo: priceProject,
      prezzorifacimento: priceReProject,
      tipifreelance: freelancerTypesProject.map(removeNonIntIds).map(ft => ({
        id: ft.id,
        id_tipofreelance: ft.id_tipofreelance,
        costonuovo: ft.costonuovo,
        costorifacimento: ft.costorifacimento,
        obbligatorio: ft.obbligatorio,
        sceglietemplate: ft.sceglietemplate
      })),
      questions: freelancerQuestions.map(fq => ({ id_domanda: fq }))
    }
    sendSaveRequest({
      methodH: pId ? 'POST' : 'PUT',
      url: pId
        ? '/sitemeup/tipologiaprogettoupdate'
        : '/sitemeup/tipologiaprogettosave',
      bodyH: data,
      manageData: res => {
        if (res.status === 'OK') {
          window.alert(res.message)
        }
      }
    })
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={9}>
            <MDBox mb={3}>
              <Grid container spacing={3}>
                <LoadingWrapper
                  loading={
                    isProjectLoading ||
                    isAllFreelancersLoading ||
                    isDelProjectLoading ||
                    isSaveLoading
                  }
                >
                  <Grid item xs={12}>
                    <Info
                      type="progetto"
                      name={nameProject}
                      nameHandler={nameHandler}
                      description={descriptionProject}
                      descriptionHandler={descriptionHandler}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Card>
                      <MDBox p={3}>
                        <MDTypography variant="h5">Freelancers</MDTypography>
                        <MDButton
                          onClick={() => addFreelancerHandler()}
                          variant="gradient"
                          color="info"
                        >
                          + Freelancer
                        </MDButton>
                        <MDBox mt={1}>
                          <Grid container spacing={3}>
                            <RepeaterFreelancers
                              freelancers={freelancerTypesProject.map(
                                freelancer => ({
                                  costonuovo: freelancer.costonuovo,
                                  costorifacimento: freelancer.costorifacimento,
                                  id: freelancer.id,
                                  id_skill: freelancer.id_skill,
                                  id_tipofreelance: freelancer.id_tipofreelance,
                                  id_tipoprogetto: freelancer.id_tipoprogetto,
                                  nome: freelancer.nome,
                                  obbligatorio: freelancer.obbligatorio,
                                  sceglietemplate: freelancer.sceglietemplate,
                                  questions: freelancerQuestions,
                                  allFreelancersTypes,
                                  allFreelancersQuestions,
                                  priceHandler:
                                    freelancerParamHandler('costonuovo'),
                                  priceReHandler:
                                    freelancerParamHandler('costorifacimento'),
                                  typeIdHandler,
                                  questionsHandler,
                                  obbligHandler:
                                    freelancerParamHandler('obbligatorio'),
                                  stHandler:
                                    freelancerParamHandler('sceglietemplate'),
                                  freelancerRemoveHandler
                                })
                              )}
                            />
                          </Grid>
                        </MDBox>
                      </MDBox>
                    </Card>
                  </Grid>

                  {/* <Grid item xs={12}>
                  <FreelanceImage
                    images={images}
                    updateFilesGallery={updateFiles}
                    filesGallery={fileImageGallery}
                    imgGalleryHandlerRemove={imgGalleryHandlerRemove}
                  />
                </Grid> */}
                </LoadingWrapper>
              </Grid>
            </MDBox>
          </Grid>
          <Grid item xs={12} lg={3}>
            <RightSidebar
              section="progetto"
              image={imageProject}
              imageHandler={imageHandler}
              imageRemoveHandler={imageRemoveHandler}
              price={priceProject}
              priceHandler={priceHandler}
              priceRe={priceReProject}
              priceReHandler={priceReHandler}
              deleteItem={deleteProject}
              saveHandler={saveHandler}
            />
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  )
}

export default EditProjectSmu
