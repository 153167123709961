import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import MDBox from 'components/MDBox'
import FormField from 'layouts/ecommerce/products/edit-product/components/FormField'
// import Autocomplete from '@mui/material/Autocomplete'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
// import { FormControl } from '@mui/material'
// import InputLabel from 'assets/theme/components/form/inputLabel'
// import MDInput from 'components/MDInput'
import MDButton from 'components/MDButton'
import { Icon } from '@mui/material'

import MDTypography from 'components/MDTypography'
import RepeaterAnswers from './RepeaterAnswers'

const Repeater = ({
  elements = [
    {
      id: 0,
      type: 0,
      isMandatory: null,
      toAddressTo: 0,
      skillId: 0,
      value: '',
      multiAnswers: [{}],
      isMulti: false
    }
  ]
}) =>
  elements.map(el => (
    <Grid item key={el.id} xs={12}>
      <Card mb={3}>
        <MDBox mb={3} p={3}>
          <Grid container spacing={3} mb={3}>
            <Grid item xs={10}>
              Domanda
              <FormField
                className="form-control"
                size="small"
                type="text"
                label="Testo domanda"
                value={el.value}
                onChange={e => el.textHandler(e.target.value, el.id)}
                style={{ width: '100%' }}
                min={null}
              />
            </Grid>

            <Grid item mt={3} xs={2}>
              <MDButton
                onClick={el.removeRowHandler(el.id)}
                color="secondary"
                style={{
                  backgroundColor: 'orange'
                }}
                variant="contained"
              >
                <Icon>delete</Icon>
                {/* - domanda */}
              </MDButton>
            </Grid>

            <Grid item xs={12}>
              <MDBox display="flex" justifyContent="space-between" gap="1rem">
                <div style={{ width: '100%' }}>
                  Tipo Domanda
                  <div style={{ width: '100%' }}>
                    <Select
                      sx={{ mt: 1, width: '100%' }}
                      value={el.type}
                      onChange={e => el.typeHandler(e.target.value, el.id)}
                    >
                      {el.types.map(t => (
                        <MenuItem key={t.value} value={t.value}>
                          {t.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </div>

                <div style={{ width: '100%' }}>
                  Obbligatoria
                  <div style={{ width: '100%' }}>
                    <Select
                      sx={{ mt: 1, width: '100%' }}
                      value={el.isMandatory}
                      onChange={e => el.mandatoryHandler(e.target.value, el.id)}
                    >
                      {el.boolTypes?.map(t => (
                        <MenuItem key={t.value} value={t.value}>
                          {t.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </div>

                {el.isSkill && (
                  // <Grid item xs={el.width}>
                  <div style={{ width: '100%' }}>
                    Da indirizzare a
                    <div style={{ width: '100%' }}>
                      <Select
                        sx={{ mt: 1, width: '100%' }}
                        value={el.toAddressTo}
                        onChange={e =>
                          el.toAddressToHandler(e.target.value, el.id)
                        }
                      >
                        {el.toAddressToTypes?.map(t => (
                          <MenuItem key={t.value} value={t.value}>
                            {t.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </div>
                )}

                {el.isFreelance && (
                  <>
                    <div>
                      Skill associata
                      <div style={{ width: '100%' }}>
                        <Select
                          sx={{ mt: 1, width: '100%' }}
                          value={el.skillId}
                          onChange={e =>
                            el.skillIdHandler(e.target.value, el.id)
                          }
                        >
                          {el.skillNames?.map(t => (
                            <MenuItem key={t.value} value={t.value}>
                              {t.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                    </div>

                    <div>
                      Moltiplicatorio
                      <div style={{ width: '100%' }}>
                        <Select
                          sx={{ mt: 1, width: '100%' }}
                          value={el.isMultiply}
                          onChange={e =>
                            el.multiplyHandler(e.target.value, el.id)
                          }
                        >
                          {el.boolTypes?.map(t => (
                            <MenuItem key={t.value} value={t.value}>
                              {t.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                    </div>

                    <div>
                      Budget
                      <div style={{ width: '100%' }}>
                        <FormField
                          type="number"
                          value={el.budget}
                          onChange={e =>
                            el.budgetHandler(e.target.value, el.id)
                          }
                        />
                      </div>
                    </div>
                  </>
                )}
              </MDBox>
            </Grid>

            {el.isMulti && (
              <Grid item xs={12}>
                <MDTypography>Risposte</MDTypography>
                <Grid container>
                  <Grid item lg={12}>
                    {el.multiAnswers.map(val => (
                      <RepeaterAnswers
                        isFreelance={el.isFreelance}
                        budget={val.budget}
                        budgetHandler={el.repeaterAnswersBudgetHandler}
                        moltiplicatorio={el.isMultiply}
                        answerId={val.id}
                        questionId={val.id_question}
                        answerKey={val.key}
                        keyHandler={el.repeaterAnswersKeyHandler}
                        value={val.value}
                        valueHandler={el.repeaterAnswersValueHandler}
                        removeRow={el.repeaterAnswersRemoveRowHandler}
                      />
                    ))}

                    <MDButton
                      disabled={!el.isMulti}
                      onClick={el.repeaterAnswersAddRowHandler(el.id)}
                      color="secondary"
                      variant="contained"
                      style={{
                        backgroundColor: '#A91354',
                        color: 'white'
                      }}
                    >
                      <Icon>add</Icon>
                      {/* + Risposta */}
                    </MDButton>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </MDBox>
      </Card>
    </Grid>
  ))

export default Repeater
