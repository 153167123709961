import React from 'react'
import MDButton from 'components/MDButton'

// EditProduct page components
import { Grid } from '@mui/material'
import FormField from 'layouts/ecommerce/products/edit-product/components/FormField'
import Delete from '@mui/icons-material/Delete'

function RepeaterAnswers({
  isFreelance,
  answerId,
  questionId,
  answerKey,
  keyHandler,
  value,
  valueHandler,
  removeRow,
  budget,
  budgetHandler
  // moltiplicatorio
}) {
  return (
    <Grid container columnSpacing={1}>
      <Grid item lg={3} sx={{ display: 'none' }}>
        <FormField
          size="small"
          value={questionId}
          // onChange={e => idquestionHandler(e.target.value, answerId)}
        />
      </Grid>
      <Grid item lg={3}>
        <FormField
          className="form-control"
          size="small"
          type="text"
          label="Identificativo risposta"
          value={answerKey}
          onChange={e => keyHandler(e.target.value, answerId, questionId)}
          style={{ width: '100%' }}
        />
      </Grid>
      <Grid item lg={budget ? 4 : 6}>
        <FormField
          className="form-control"
          size="small"
          type="text"
          label="Risposta"
          value={value}
          onChange={e => valueHandler(e.target.value, answerId, questionId)}
          style={{ width: '100%' }}
        />
      </Grid>
      {isFreelance ? (
        <Grid item lg={2}>
          <FormField
            className="form-control"
            size="small"
            type="number"
            label="Budget"
            value={budget}
            onChange={e => budgetHandler(e.target.value, answerId, questionId)}
            style={{ width: '100%' }}
            min="0"
          />
        </Grid>
      ) : (
        ''
      )}
      <Grid item lg={3} marginTop={3}>
        <MDButton
          onClick={removeRow({ answerId, questionId })}
          color="secondary"
          style={{
            backgroundColor: 'orange'
          }}
          variant="contained"
        >
          <Delete />
          {/* - risposta */}
        </MDButton>
      </Grid>
    </Grid>
  )
}
export default RepeaterAnswers
