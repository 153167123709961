/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 PRO React layouts
// import Analytics from 'layouts/dashboards/analytics'

// import Sales from 'layouts/dashboards/sales'

import ArchiveSmu from 'pagesmu/CustomComponents/Archive/ArchiveSmu'
import ListSmu from 'pagesmu/CustomComponents/Archive/ListSmu'
// import EditProductNsp from 'pagesmu/gestproduct/productdetail/EditProductNsp'
// import ArchiveProductNsp from 'pagesmu/gestproduct/productarchive/ArchiveProductNsp'
// import ArchiveSkillSmu from 'pagesmu/gestskill/skillarchive/ArchiveSkillSmu'
// import ArchiveFreelanceSmu from 'pagesmu/gestFreelance/freelancearchive/ArchiveFreelanceSmu'
// import ArchiveProjectSmu from 'pagesmu/gestProject/projectarchive/ArchiveProjectSmu'
import EditSkillSmu from 'pagesmu/gestskill/EditSkillSmu'
import EditFreelanceSmu from 'pagesmu/gestFreelance/EditFreelanceSmu'
import EditTemplateSmu from 'pagesmu/gestTemplate/EditTemplateSmu'
import EditProjectSmu from 'pagesmu/gestProject/EditProjectSmu'

// import TagConfigNsp from 'pagesmu/gestconfig/tag/TagConfigNsp'
// import CategoryConfigNsp from 'pagesmu/gestconfig/category/CategoryConfigNsp'
// import SubcategoryConfigNsp from 'pagesmu/gestconfig/subcategory/SubcategoryConfigNsp'
// import PersonalFieldsConfigNsp from 'pagesmu/gestconfig/personalfields/PersonalFieldsConfigNsp'
// import PersonaldocumentationConfigNsp from 'pagesmu/gestconfig/personaldocumentation/PersonaldocumentationConfigNsp'
// import FaqConfigNsp from 'pagesmu/gestconfig/faq/FaqConfigNsp'

import LoginPage from 'pagesmu/GestAccess/login/GestLogin'
import PasswordReset from 'pagesmu/GestAccess/resetpwd/GestResetPwd'
import RegistrationPage from 'pagesmu/GestAccess/register/GestRegister'
// import ArchiveUserNsp from 'pagesmu/GestUser/usersarchive/ArchiveUserNsp'
// import EditUserNsp from 'pagesmu/GestUser/userdetail/EditUsreNSP'
// import ArchiveDepartmentsNsp from 'pagesmu/GestDepartments/departmentsarchive/ArchiveDepartmentsNsp'
// import EditDepartmentNSP from 'pagesmu/GestDepartments/departmentdetail/EditDepartmentNSP'
// import ArchiveCategorySkill from 'pagesmu/gestconfig/categoryskill/CategorySkillConfig'
// import ArchiveCategoryQuestions from 'pagesmu/gestconfig/questiontypes/QuestionTypesConfig'
// import ArchiveProducts from 'pagesmu/gestconfig/prodottiaggiuntivi/ProdottiAggiuntiviConfig'
// Material Dashboard 2 PRO React components
// import MDAvatar from 'components/MDAvatar'

// @mui icons
import Icon from '@mui/material/Icon'

// Images
// import profilePicture from 'assets/images/team-3.jpg'

// Test
import TestPage from 'pagesmu/testPage/TestPage'

const routes = [
  { type: 'title', title: 'Pages', key: 'title-pages' },
  {
    type: 'collapse',
    name: 'Gestione',
    isType: ['admin', 'Operator'],
    key: 'gestione',
    icon: <Icon fontSize="medium">apps</Icon>,
    collapse: [
      {
        name: 'Skills',
        isType: ['admin', 'Operator'],
        key: 'gestioneskill',
        collapse: [
          {
            name: 'Elenco Skill',
            key: 'skills',
            isType: ['admin', 'Operator'],
            route: '/gestione/skills',
            component: <ArchiveSmu type="skill" />
          },
          {
            type: 'hidden',
            name: 'Singola Skill',
            key: 'Singolaskill',
            route: '/gestione/skill/:id',
            component: <EditSkillSmu />
          },
          {
            name: 'Nuova Skill',
            key: 'Nuovaskill',
            isType: ['admin'],
            route: '/gestione/nuovaskill',
            component: <EditSkillSmu />
          }
        ]
      },
      {
        name: 'Tipologie freelance',
        isType: ['admin', 'Operator'],
        key: 'gestionefreelancetype',
        collapse: [
          {
            name: 'Elenco tipologie',
            key: 'tipologiefreelance',
            isType: ['admin', 'Operator'],
            route: '/gestione/tipologiefreelance',
            component: <ArchiveSmu type="freelance" />
          },
          {
            type: 'hidden',
            name: 'Singola Tipologia',
            key: 'Singolatipologia',
            route: '/gestione/tipologiafreelance/:id',
            component: <EditFreelanceSmu />
          },
          {
            name: 'Nuova tipologia',
            key: 'Nuovatipologia',
            isType: ['admin'],
            route: '/gestione/nuovatipologia',
            component: <EditFreelanceSmu />
          }
        ]
      },
      {
        name: 'Progetti',
        isType: ['admin', 'Operator'],
        key: 'gestioneprogetti',
        collapse: [
          {
            name: 'Elenco progetti',
            key: 'progetti',
            isType: ['admin', 'Operator'],
            route: '/gestione/progetti',
            component: <ArchiveSmu type="progetto" />
          },
          {
            type: 'hidden',
            name: 'Singolo progetto',
            key: 'Singoloprogetto',
            route: '/gestione/tipologiaprogetto/:id',
            component: <EditProjectSmu />
          },
          {
            name: 'Nuovo progetto',
            key: 'Nuovoprogetto',
            isType: ['admin'],
            route: '/gestione/nuovoprogetto',
            component: <EditProjectSmu />
          }
        ]
      },
      {
        name: 'Template',
        isType: ['admin', 'Operator'],
        key: 'gestionetemplate',
        collapse: [
          {
            name: 'Elenco template',
            key: 'template',
            isType: ['admin', 'Operator'],
            route: '/gestione/templates',
            component: <ArchiveSmu type="template" />
          },
          {
            type: 'hidden',
            name: 'Singolo template',
            key: 'Singolotemplate',
            route: '/gestione/template/:id',
            component: <EditTemplateSmu />
          },
          {
            name: 'Nuovo template',
            key: 'Nuovotemplate',
            isType: ['admin'],
            route: '/gestione/nuovotemplate',
            component: <EditTemplateSmu />
          }
        ]
      },
      // {
      //   name: 'Prodotti',
      //   isType: ['admin', 'Operator'],
      //   key: 'gestioneprodotti',
      //   collapse: [
      //     {
      //       name: 'Elenco Prodotti',
      //       key: 'prodottie',
      //       isType: ['admin', 'Operator'],
      //       route: '/gestione/prodottie',
      //       component: <ArchiveProductNsp />
      //     },
      //     {
      //       name: 'Singolo Prodotto',
      //       key: 'Singolo prodotto',
      //       route: '/gestione/prodotto/:id',
      //       component: <EditProductNsp />
      //     },
      //     {
      //       name: 'Nuovo Prodotto',
      //       key: 'Nuovo prodotto',
      //       isType: ['admin'],
      //       route: '/gestione/prodotto',
      //       component: <EditProductNsp />
      //     }
      //   ]
      // },
      {
        name: 'Utenti',
        isType: ['admin', 'Operator'],
        key: 'gestionefreelances',
        collapse: [
          {
            name: 'Elenco Freelance',
            key: 'freelances',
            isType: ['admin'],
            route: '/gestione/all-freelances',
            component: <ListSmu type="allFreelances" />
          },
          {
            name: 'Elenco Clienti',
            key: 'clienti',
            route: '/gestione/all-clienti',
            component: <ListSmu type="allClienti" />
          }
        ]
      }
      // {
      //   name: 'Uffici',
      //   isType: ['admin', 'Operator'],
      //   key: 'gestioneuffici',
      //   collapse: [
      //     {
      //       name: 'Elenco Uffici',
      //       key: 'prodottie',
      //       route: '/gestione/uffici/',
      //       component: <ArchiveDepartmentsNsp />
      //     },
      //     {
      //       name: 'Singolo ufficio',
      //       key: 'utenti',
      //       route: '/gestione/ufficio/:id',
      //       component: <EditDepartmentNSP />
      //     },
      //     {
      //       name: 'Nuovo ufficio',
      //       key: 'utenti',
      //       route: '/gestione/ufficio/',
      //       component: <EditDepartmentNSP />
      //     }
      //   ]
      // }
    ]
  },
  // {
  //   type: 'collapse',
  //   name: 'Configurazioni',
  //   isType: ['admin', 'Operator'],
  //   key: 'configurazioni',
  //   icon: <Icon fontSize="medium">apps</Icon>,
  //   collapse: [
  //     {
  //       name: 'Tag',
  //       isType: ['admin', 'Operator'],
  //       key: 'tag',
  //       route: '/config/tag',
  //       component: <TagConfigNsp />
  //     },
  //     {
  //       name: 'Categorie',
  //       key: 'categorie',
  //       route: '/config/categorie',
  //       component: <CategoryConfigNsp />
  //     },
  //     {
  //       name: 'Sotto categorie',
  //       key: 'sottocategorie',
  //       route: '/config/sottocategorie',
  //       component: <SubcategoryConfigNsp />
  //     },
  //     {
  //       name: 'Campi anagrafici',
  //       key: 'campianagrafici',
  //       route: '/config/campianagrafici',
  //       component: <PersonalFieldsConfigNsp />
  //     },
  //     {
  //       name: 'Documenti richiedibili',
  //       key: 'documentirichiedibili',
  //       route: '/config/documentirichiedibili',
  //       component: <PersonaldocumentationConfigNsp />
  //     },
  //     {
  //       name: 'Faq',
  //       key: 'faq',
  //       route: '/config/faq',
  //       component: <FaqConfigNsp />
  //     },
  //     {
  //       name: 'Categorie Skill',
  //       isType: ['Admin'],
  //       key: 'categorieskill',
  //       route: '/gestione/categorieskill/',
  //       component: <ArchiveCategorySkill />
  //     },
  //     {
  //       name: 'Tipologie Domande Skill',
  //       isType: ['Admin'],
  //       key: 'categoriedomanda',
  //       route: '/gestione/categoriedomanda/',
  //       component: <ArchiveCategoryQuestions />
  //     },
  //     {
  //       name: 'Prodotti aggiuntivi',
  //       isType: ['Admin'],
  //       key: 'prodittiaggiuntivi',
  //       route: '/gestione/prodottiaggiuntivi/',
  //       component: <ArchiveProducts />
  //     }
  //   ]
  // },
  {
    type: 'collapse',
    name: 'Accesso',
    key: 'accesso',
    icon: <Icon fontSize="medium">apps</Icon>,
    collapse: [
      {
        name: 'Login',
        key: 'login',
        route: '/login',
        component: <LoginPage />
      },
      {
        name: 'ResetPwd',
        key: 'resetpwd',
        route: '/restepwd',
        component: <PasswordReset />
      },
      {
        name: 'Register',
        key: 'register',
        route: '/register',
        component: <RegistrationPage />
      },
      { name: 'TestP', key: 'testp', route: '/testp', component: <TestPage /> }
    ]
  }
]

export default routes
