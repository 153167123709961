/* eslint-disable prettier/prettier */
/* eslint-disable no-alert */
import { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

// @mui material components
import Grid from '@mui/material/Grid'

// Material Dashboard 2 PRO React components
import MDButton from 'components/MDButton'
import MDBox from 'components/MDBox'

// Material Dashboard 2 PRO React example components
import DashboardLayout from 'commoncomponent/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'commoncomponent/Navbars/DashboardNavbar'
import Footer from 'commoncomponent/Footer'

// Custom Hooks
import useHttp from 'hooks/use-http'

// EditSkill page components
// import FreelanceImage from 'pagesmu/gestFreelance/freelancedetail/components/FreelanceImage/FreelanceImage'
import Info from 'pagesmu/CustomComponents/Info'
import RightSidebar from 'pagesmu/CustomComponents/RightSidebar'
// import FreelanceDxSidebar from 'pagesmu/gestFreelance/freelancedetail/components/FreelanceDxSidebar/FreelanceDxSidebar'
// import RepeaterQuestions from 'pagesmu/gestFreelance/freelancedetail/components/RepeaterQuestions/RepeaterQuestions'
import CustomRepeater from 'pagesmu/CustomComponents/Repeaters/Repeater'
import LoadingWrapper from 'pagesmu/CustomComponents/LoadingWrapper/LoadingWrapper'
import { removeNonIntIds, prepImage, feedback } from '../../utils/utils'

function EditFreelanceSmu() {
  const { id: pId } = useParams()

  const [nameFreelance, setNameFreelance] = useState('')
  const [descriptionFreelance, setDescriptionFreelance] = useState('')
  const [imageFreelance, setImageFreelance] = useState('')
  const [imageExtensionFreelance, setImageExtensionFreelance] = useState('')
  const [priceFreelance, setPriceFreelance] = useState(0)
  const [skillNames, setSkillNames] = useState([
    { label: '(Scegli)', value: -1 },
    { label: 'Nessuna', value: 0 },
  ])

  const questionTypes = [
    { label: '(Scegli)', value: 0 },
    { label: 'Testo libero', value: 1 },
    { label: 'Risposta multipla', value: 3 }
  ]

  const boolTypes = [
    { label: '(Scegli)', value: -1 },
    { label: 'No', value: 0 },
    { label: 'Si', value: 1 }
  ]

  const defaultQuestion = {
    id: 0,
    text: '',
    id_question_type: 0,
    mandatory: -1,
    moltiplicatorio: -1,
    id_skill: -1,
    budget: 0,
    answers: []
  }
  const [repeaterQuestions, setRepeaterQuestions] = useState([defaultQuestion])

  const navigate = useNavigate()
  
  const repeaterQuestionsAddRowHandler = () => {
    setRepeaterQuestions(old => [
      ...old,
      {
        ...defaultQuestion,
        id: `${old?.length + 1}prov`
      }
    ])
  }

  const repeaterAnswersAddRowHandler = id => () => {
    setRepeaterQuestions(old =>
      old.map(q =>
        q.id === id
          ? {
            ...q,
            answers: [
              ...q.answers,
              {
                key: '',
                value: '',
                id_question: id,
                id: `${q.answers.length + 1}ans`,
                moltiplicatorio: q.moltiplicatorio,
              }
            ]
          }
          : q
      )
    )
  }

  const repeaterQuestionsHandler = param => (value, id) => {
    setRepeaterQuestions(old =>
      old.map(r => (r.id === id ? { ...r, [param]: value } : r))
    )
  }

  const repeaterAnswersHandler = param => (value, answerId, questionId) => {
    setRepeaterQuestions(old =>
      old.map(r =>
        r.id === questionId
          ? {
            ...r,
            answers: r.answers.map(a =>
              a.id === answerId ? { ...a, [param]: value } : a
            )
          }
          : r
      )
    )
  }

  const repeaterQuestionsRemoveRowHandler = id => () => {
    setRepeaterQuestions(old => old.filter(rq => rq.id !== id))
  }

  const repeaterAnswersRemoveRowHandler =
    ({ questionId, answerId }) =>
      () => {
        setRepeaterQuestions(old =>
          old.map(rq =>
            rq.id === questionId
              ? { ...rq, answers: rq.answers.filter(ra => ra.id !== answerId) }
              : rq
          )
        )
      }

  useEffect(() => {
    const totalPrice = repeaterQuestions
      .map(q => parseInt(q.budget, 10))
      .reduce((a, b) => a + b)
    setPriceFreelance(totalPrice)
  }, [repeaterQuestions])

  const {
    sendRequest: sendFreelanceRequest,
    isLoading: isFreelanceLoading,
    // error: freelanceError
  } = useHttp()

  // Images
  const imageHandler = image => {
    // const mime = image.type
    const reader = new FileReader()
    reader.readAsDataURL(image)
    reader.onload = e => {
      // const mime = e.target.result.match(/^data:(.*);/)[1]
      // const base64 = e.target.result.replace(`data:${mime};base64,`, '')
      const base64 = e.target.result
      // console.log('MAINIMAGE', base64)
      setImageFreelance(base64)
      setImageExtensionFreelance(image.type.split('/')[1])
    }
  }

  const imageRemoveHandler = () => {
    setImageFreelance('')
    setImageExtensionFreelance('')
  }

  // Fine images

  const {
    sendRequest: sendDelFreelanceRequest,
    isLoading: isDelFreelanceLoading
    // error: delFreelanceError
  } = useHttp()

  const deleteFreelance = () => {
    if (
      window.confirm(
        'Sei sicuro di voler eliminare questa tipologia di freelance?'
      )
    ) {
      sendDelFreelanceRequest({
        methodH: 'DELETE',
        url: '/sitemeup/tipologiafreelancedelete',
        body: { id: pId },
        manageData: () => navigate('gestione/tipologiefreelance')
      })
    }
  }

  const {
    sendRequest: sendSaveRequest,
    isLoading: isSaveLoading,
    // error: saveError
  } = useHttp()

  const manageFreelanceGet = ({
    name: nameG,
    description: descriptionG,
    pathfoto: imagepathG,
    questions: questionsG
  }) => {
    setNameFreelance(nameG)
    setDescriptionFreelance(descriptionG)
    setImageFreelance(imagepathG)
    setRepeaterQuestions(Object.values(questionsG).flat())
  }
  useEffect(() => {
    if (pId) {
      sendFreelanceRequest({
        url: `/sitemeup/tipologiafreelance?id=${pId}`,
        manageData: ({ data }) => {
          manageFreelanceGet(data)
        }
      })
    }
  }, [pId])

  const nameHandler = e => {
    setNameFreelance(e.target.value)
  }

  const descriptionHandler = e => {
    setDescriptionFreelance(e)
  }

  const saveHandler = () => {
    const data = {
      id: pId,
      description: descriptionFreelance,
      name: nameFreelance,
      estensionefoto: imageExtensionFreelance,
      foto: prepImage(imageFreelance),
      questions: repeaterQuestions
      .map(removeNonIntIds)
      .map(question => ({ ...question, answers: question.answers.map(removeNonIntIds) }))
      .map(question => {
        if(question.id_skill < 1) return ({ ...question, id_skill: null, skill: {} })
        return question
      })
  }
    sendSaveRequest({
      methodH: pId ? 'POST' : 'PUT',
      url: pId
        ? '/sitemeup/tipologiafreelanceupdate'
        : '/sitemeup/tipologiafreelancesave',
      bodyH: data,
      manageData: feedback
    })
  }

  const {
    sendRequest: sendSkillsRequest,
    isLoading: isSkillsLoading,
    // error: skillsError
  } = useHttp()

  const handleSkillNames = skills => {
    const newSkills = skills.data.map(skill => ({
      value: skill.id,
      label: skill.name
    }))
    setSkillNames([...skillNames, ...newSkills])
  }

  useEffect(() => {
    sendSkillsRequest({
      url: '/sitemeup/freelanceskills',
      manageData: handleSkillNames
    })
  }, [])

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={9}>
            <MDBox mb={3}>
              <Grid container spacing={3}>
                <LoadingWrapper loading={
                  isFreelanceLoading ||
                  isSkillsLoading ||
                  isDelFreelanceLoading ||
                  isSaveLoading
                  }
                >
                <Grid item xs={12}>
                  <Info
                    type="freelance"
                    name={nameFreelance}
                    nameHandler={nameHandler}
                    description={descriptionFreelance}
                    descriptionHandler={descriptionHandler}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Grid container rowSpacing={2}>
                    <CustomRepeater
                      elements={repeaterQuestions.map(q => ({
                        ...q,
                        isFreelance: true,
                        width: 12 / 5,
                        type: q.id_question_type,
                        types: questionTypes,
                        skillNames,
                        isMandatory: q.mandatory,
                        boolTypes,
                        value: q.text,
                        budget: q.budget,
                        skillId: q.id_skill,
                        isMultiply: q.moltiplicatorio,
                        multiAnswers: q.answers,
                        isMulti: q.id_question_type === 3,
                        textHandler: repeaterQuestionsHandler('text'),
                        typeHandler:
                          repeaterQuestionsHandler('id_question_type'),
                        mandatoryHandler: repeaterQuestionsHandler('mandatory'),
                        budgetHandler: repeaterQuestionsHandler('budget'),
                        multiplyHandler:
                          repeaterQuestionsHandler('moltiplicatorio'),
                        skillIdHandler: repeaterQuestionsHandler('id_skill'),
                        repeaterAnswersKeyHandler:
                          repeaterAnswersHandler('key'),
                        repeaterAnswersValueHandler:
                          repeaterAnswersHandler('value'),
                        repeaterAnswersBudgetHandler:
                          repeaterAnswersHandler('budget'),
                        // repeaterAnswersMoltHandler:
                        //   repeaterAnswersHandler('moltiplicatorio'),
                        removeRowHandler: repeaterQuestionsRemoveRowHandler,
                        repeaterAnswersAddRowHandler,
                        repeaterAnswersRemoveRowHandler
                      }))}
                    />
                    <Grid item>
                      <MDButton
                        onClick={() => repeaterQuestionsAddRowHandler()}
                        variant="gradient"
                        color="info"
                      >
                        + Domanda
                      </MDButton>
                    </Grid>
                  </Grid>
                </Grid>
              </LoadingWrapper>
              </Grid>
            </MDBox>
          </Grid>
          <Grid item xs={12} lg={3}>
            <RightSidebar
              section="freelance"
              price={priceFreelance}
              saveHandler={saveHandler}
              image={imageFreelance}
              imageHandler={imageHandler}
              imageRemoveHandler={imageRemoveHandler}
              deleteItem={deleteFreelance}
            />
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  )
}

export default EditFreelanceSmu
